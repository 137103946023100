/// <reference path="../settings/variables" />


@mixin fontPrimary( $size: 18px, $height: 30px ) {
	@include fontMontserrat();
	font-size: $size;
	color: $colorText;
	line-height: $height;
}

@mixin fontMontserrat() {
	font: {
		family: 'Montserrat', serif;
	}
}

@mixin fontSecondary( $size: 25px, $height: 40px) {

	@include fontZilla();
	font-size: $size;
	color: $colorTitle;
	line-height: $height;
	/*text-transform: uppercase;*/
}

@mixin fontZilla() {
	font: {
		family: 'Zilla Slab', serif;
	}
}

@mixin fontVisual ($size: 60px, $height: 89px) {
	font: {
		family: "Lemon Tuesday", "Zilla Slab", serif;
		size: $size;
	}

	color: $colorPrimaryGreyTint1;
	line-height: $height;
	text-transform: unset;
	font-weight: 400;
}

@mixin fontLemon() {
	font: {
		family: "Lemon Tuesday", "Zilla Slab", serif;
	}
}

@mixin fontSize( $size: null, $height: null, $weight: null ) {
	@if ($size) {
		font-size: $size;
	}

	@if ($height) {
		line-height: $height;
	}

	@if ($weight) {
		font-weight: $weight;
	}
}

@mixin active() {
	&:focus, &:active, &:hover {
		@content;
	}
}

@mixin transition( $property: all, $duration: 400ms, $timingFunction: cubic-bezier(.4, .02, .15, 1) ) {
	transition: $property $duration $timingFunction;
}

@mixin animation( $name, $duration: 400ms, $timingFunction: cubic-bezier(.4, .02, .15, 1) ) {
	animation: {
		name: $name;
		duration: $duration;
		direction: normal;
	}
}
