﻿/// <reference path="../settings/variables" />
/// <reference path="../settings/mixins" />

body {

	&.homepage {
		section.pageContent {
			padding-bottom: 0px;
		}
	}

	section.pageContent {
		position: relative;
		padding-bottom: 100px;
	}

	section.readMore {
		.content {
			overflow: hidden;

			&.animate {
				@include transition(max-height, 800ms);
			}
		}

		.gradient {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 200px;
			transform: translateY(-100%);
			background: linear-gradient(rgba(255, 255, 255, 0), white 70%);
		}

		.options {
			display: none;
		}

		&.showMore {
			.options {
				display: block;
			}
		}
	}

	.umb-grid {
		.row {
			margin-bottom: 40px;

			&.timeperiod {
				h2, p {
					@include fontZilla();
					@include fontSize(30px, 36px, 400);
					color: $colorPrimaryGreyShade2;
					opacity: 0.5;
					margin: 0 0;
				}
			}

			h2, h3 {
				margin-bottom: 20px;
			}

			p {
				.externalLink {
					a {
						position: relative;
						padding: 0 23px 0 0;

						&:after {
							position: absolute;
							right: 0;
							top: 2px;
							width: 16px;
							height: 16px;
							content: ' ';
							background: url('/img/icons/content-externallink.png') no-repeat;
						}
					}
				}

				.locationLink {
					a {
						position: relative;
						padding: 0 18px 0 0;

						&:after {
							position: absolute;
							right: 0;
							top: 1px;
							width: 13px;
							height: 18px;
							content: ' ';
							background: url('/img/icons/content_marker.png') no-repeat;
						}
					}
				}
			}
		}



		.streamer {
			position: relative;
			background: $colorPrimaryGreyTint1;
			margin: 60px 0;
			padding: 75px 0;

			.row {
				margin-bottom: 0px;

				.col {
					margin: 0 auto;

					p {
						text-align: center;
						@include fontLemon();
						font-size: 45px;
						line-height: 45px;
						padding: 0 30px;
					}
				}
			}

			.svgHolder {
				&.edge-section-bottom {
					bottom: -30px;
					left: 0;

					svg {
						transform: rotate(180deg);
						transform-origin: 25%;
					}
				}

				svg {
					use {
						fill: $colorPrimaryGreyTint1;
					}
				}
			}
		}

		.contentImage {
			position: relative;

			svg.image_mask_content {
				position: absolute;
				top: 0;
				height: 100%;
				width: 100%;
				left: 0;
				transform: scaleX(1.001);
			}

			img{
				margin-bottom:1px;
			}
		}

		.contentimagelarge {
			margin: 45px 0;
		}
	}
}
