﻿/// <reference path="../settings/variables" />
/// <reference path="../settings/mixins" />

body {

    &.homepage {
        .pageHeader {
            &.centered {
                .textHolder {
                    bottom: 120px;

                    @include media-breakpoint-down(sm) {
                        bottom: 30px;
                    }
                }

                svg.icon_carousel_arrow {
                    cursor: pointer;
                    margin-top: 20px;

                    use {
                        fill: #fff;
                    }
                }
            }

            .headerImage {
                height: 710px;
                overflow: hidden;

                @include media-breakpoint-down(sm) {
                    height: 300px;
                }

                .imageHolderUrh.mobile {
                    display: none;

                    @include media-breakpoint-down(sm) {
                        display: block;
                    }
                }

                video {
                    height: auto;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;

                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
            }
        }
    }

    .pageHeader {
        .headerImage {
            height: 470px;
            width: 100%;
            overflow: hidden;

            .imageHolderUrh {
                position: relative;

                .gradient {
                    position: absolute;
                    width: 100%;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(180deg, rgba(0,0,0,0), rgba(0,0,0,0.34));
                    /*opacity: 0.4;*/
                }

                img {
                    width: 100%;
                }
            }
        }

        .textHolder {
            position: absolute;
            bottom: 50px;
            left: 0;
            text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
            width: 100%;

            h1 {
                text-transform: uppercase;
                letter-spacing: 2px;
                margin-bottom: 20px;
            }

            span.type {
                @include fontPrimary(18px, 22px);
                font-weight: 700;
                text-transform: uppercase;
                color: $colorTitle;
                margin-bottom: 15px;
                display: block;

                svg {
                    use {
                        fill: white;
                    }
                }

                @include media-breakpoint-down(sm) {
                    @include fontSize(14px, 18px);
                }
            }

            span.nextDate {
                &:before {
                    content: '‒';
                    margin-right: 10px;
                }


                @include fontSize(24px, 29px);
                color: $colorTitle;
            }
        }

        @include media-breakpoint-down(sm) {
            .headerImage {
                height: 300px;
            }

            .edge-header-top {
                display: none;
            }
        }

        &.centered {
            .textHolder {
                bottom: 70px;
                text-align: center;

                .chapeau {
                    @include fontMontserrat();
                    @include fontSize(20px, 24px);
                    font-weight: normal;
                    text-transform: unset;
                    text-shadow: 2px 2px 2px rgba(0,0,0,0.3);
                }
            }
        }
    }

    .headerImage, .subHeader {
        position: relative;
    }

    .subHeader {
        padding-bottom: 40px;
    }
}
