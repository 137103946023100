﻿/// <reference path="../../settings/variables" />
/// <reference path="../../settings/mixins" />

body.storiesOverviewPage {
	#calendarFilter {

		.themeFilter {
			margin-bottom: 30px;

			@include media-breakpoint-down(sm) {
				margin-bottom: 50px;
			}
		}
	}
}


