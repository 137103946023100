
// Base Colors
$colorPrimaryRed: #DB2B27;
$colorPrimaryBlue: #7299A8;
$colorPrimaryGreen: #8EBCAC;
$colorPrimaryGreenShade1: #4A5042;
$colorPrimaryGreyShade2: #38393A ;
$colorPrimaryGreyShade2: #414648;
$colorPrimaryGreyShade1: #535C3E;
$colorPrimaryGrey: #E7E7E1;
$colorPrimaryGreyTint1: #F7F8F9;
$colorPrimaryGreyTint2: #CFD1D1;
$colorPrimaryGreyInactive: #AEB1B2;
$colorPrimaryGreyTextShadow: #000000;
$colorPrimaryGreyInactiveThirty: #C4C6C7;


// Font colors
$colorText: $colorPrimaryGreyShade2;
$colorTitle: $colorPrimaryGreyTint1;




// Button colors

$colorButtonText: $colorTitle;
$colorButtonVisual: $colorPrimaryBlue;
$colorButtonPrimary: $colorPrimaryGrey;
$colorButtonSecondary: #FFFFFF;




//Erfgoedlijnen

$colorHeritageLineYellow: #CDA33B;
$colorHeritageLineGreen: #437343;
$colorHeritageLineBrown: #7A4B2C;
$colorHeritageLineBlue: #5C8C99;
$colorHeritageLinePurple: #995C7C;
$colorHeritageLinePink: #c2185E;



// Visual icons

$colorIconGreenAccent1: #99995C;
$colorIconGreen: #5C995C;
$colorIconBlue: #5C9999;
$colorIconRose: 995C5C;
$colorIconPurple: 995C7C;
$colorIconPurpleAccent: 5C5C99;

//Special colors
$colorGrayInput: #979797;
// Sizes
$sizeMenuHeight: 0;