﻿/// <reference path="../settings/variables" />
/// <reference path="../settings/mixins" />

body {

	div.carousel {
		margin: 30px -45px;

		@include media-breakpoint-down(sm) {
			margin: 30px 0;
		}

		a {
			position: relative;
			margin: 0 auto;
			max-width: 360px;
			height: 395px;
			display: block;

			@include media-breakpoint-down(md) {
				height: 275px;
			}

			@include media-breakpoint-down(sm) {
				height: 175px;
			}

			div.carouselImage {
				width: 100%;
				height: 100%;

				img {
					max-height: 100%;
					width: 100%;
				}

				div.gradient {
					top: 0;
					position: absolute;
					width: 100%;
					height: 100%;
					background: linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%);
				}

				div.textHolder {
					position: absolute;
					padding: 95px 50px;
					bottom: 0;
					width: 100%;

					@include media-breakpoint-down(md) {
						bottom: 15px;
					}

					h3 {
						color: $colorPrimaryGreyTint1;
						text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
					}

					p.shortIntro {
						color: $colorPrimaryGreyTint1;
						text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
						max-height: 0;
						overflow: hidden;
						@include transition(max-height, 800ms);
						@include fontSize(16px, 25px);
						margin: 0;
					}
				}

				@include active() {
					div.textHolder {
						p.shortIntro {
							max-height: 100px;

							@include media-breakpoint-down(sm) {
								max-height: 0px;
							}
						}
					}
				}

				@include media-breakpoint-down(md) {
					width: 250px;
					height: 273px;
				}

				@include media-breakpoint-down(sm) {
					width: 160px;
					height: 175px;
				}
			}
		}

		&.slick-slider {
			svg.slick-arrow {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				cursor: pointer;
				z-index: 2;

				&.previous {
					transform: translateY(-50%) rotate(180deg);
					right: initial;
					left: 0;
				}
			}

			@include media-breakpoint-down(md) {
				div.carouselImage {
					width: 250px;
					height: 273px;
					margin: 0 auto;

					position: relative;

					div.textHolder {
						padding: 30px 15px;

						h3 {
							@include fontSize(20px, 25px);
						}
					}
				}
			}

			@include media-breakpoint-down(sm) {
				div.carouselImage {
					width: 160px;
					height: 175px;
				}
			}
		}
	}



	.dots {
		.slick-dots {
			list-style: none;
			padding: 0;
			display: flex;
			justify-content: center;

			li {
				padding: 0;
				margin: 5px 10px;
				width: 12px;
				height: 12px;
				border-radius: 50%;
				background-color: $colorPrimaryGrey;
				opacity: 0.5;
				cursor: pointer;

				&.slick-active {
					background-color: $colorPrimaryGreyTint1;
					opacity: 1;
				}

				&:before {
					display: none;
				}

				button {
					display: none;
				}
			}
		}
	}
}
