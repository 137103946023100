﻿/// <reference path="../settings/variables" />
/// <reference path="../settings/mixins" />

body {

	&.homepage {
		header {
			.menuItems {
				nav {
					ul {
						li {
							a {
								color: #fff;
								text-shadow: 2px 2px 2px rgba(0,0,0,0.3);

								.menu_underline {
									use {
										fill: #fff;
									}
								}
							}
						}
					}
				}
			}

			div.search {
				input {
					background-color: transparent;
					border: 1px solid rgba(255, 255, 255, .5);
					color: #fff;
					@include transition(all, 0.3s);

					&::-webkit-input-placeholder {
						color: #fff;
					}


					&:focus {
						border: 1px solid #fff;
						color: #fff;

						& ~ .icon_search {
							border-left: 1px solid #fff;
							fill: #fff;
							@include transition(all, 0.3s);

							use {
								fill: #fff;
								@include transition(all, 0.3s);
							}
						}
					}
				}

				.icon_search {
					background-color: transparent;
					border-left: 1px solid rgba(255, 255, 255, .5);

					use {
						fill: #fff;
					}
				}
			}

			div.languageSelection {
				div.selectedLanguage {
					color: #fff;
					@include transition(color);
					text-shadow: 2px 2px 2px rgba(0,0,0,0.3);

					&:after {
						text-shadow: none;
					}
				}
			}


			&.scrolled {
				.menuItems {
					nav {
						ul {
							li {
								@include media-breakpoint-up(md) {
									a {
										color: $colorPrimaryGreyShade2;
										text-shadow: none;

										.menu_underline {
											use {
												fill: $colorPrimaryGreen;
											}
										}
									}
								}
							}
						}
					}
				}


				div.search {
					input {
						background-color: $colorPrimaryGreyTint1;
						color: $colorText;
						border: none;

						&::-webkit-input-placeholder {
							color: $colorText;
						}

						&:focus {
							color: $colorText;

							& ~ .icon_search {
								use {
									fill: $colorIconGreenAccent1;
								}
							}
						}
					}

					.icon_search {
						background-color: $colorPrimaryGrey;
						border: none;

						use {
							fill: $colorIconGreenAccent1;
						}
					}
				}

				div.languageSelection {
					div.selectedLanguage {
						color: $colorText;
						text-shadow: none;
					}
				}
			}
		}
	}

	header {
		width: 100%;
		position: fixed;
		top: 0;
		z-index: 9999;
		@include transition();

		.col {
			position: relative;
		}

		a.logo {
			display: flex;
			width: 100%;
			align-items: center;

			svg {
				@include transition();

				use {
					@include transition(fill);
					fill: white;
				}
			}
		}

		div.col {
			display: flex;
		}

		.menuItems {
			@include transition();
			width: 100%;
			overflow-y: auto;

			@include media-breakpoint-up(md) {
				overflow-y: hidden;
			}

			.search {
				display: none;
			}

			nav {
				height: 100%;

				ul {
					height: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 100%;
					list-style: none;
					margin: 0;
					padding: 0;

					li {
						a {
							position: relative;
							text-decoration: none;
							color: $colorPrimaryGreyShade2;
							font-size: 20px;
							font-weight: 500;

							.menu_underline {
								display: none;
							}

							@include active() {
								text-decoration: none;

								.menu_underline {
									display: block;
								}
							}

							&.active {
								.menu_underline {
									display: block;
								}
							}
						}
					}

					&.mobile {
						display: none;
					}

					&.languages {
						position: absolute;
						display: none;
					}
				}
			}
		}

		div.search {
			@include transition();
		}

		div.languageSelection {
			@include transition();
			margin: 30px 0;
			height: 50px;
			text-align: center;
			position: relative;
			display: none;

			@include media-breakpoint-up(md) {
				display: block;
			}

			div.selectedLanguage {
				line-height: 50px;
				font-weight: 500;
				cursor: pointer;

				&:after {
					content: '\25bc';
					font-size: 0.5em;
					/* padding-bottom: 5px; */
					position: absolute;
					display: inline-block;
					padding-left: 3px;
					@include transition(transform);
					transform-origin: center;
				}
			}

			ul.languages {
				position: absolute;
				background: #fff;
				border: 1px solid $colorPrimaryGrey;
				top: 40px;
				right: 0;
				margin: 0;
				padding: 23px 30px 23px 40px;
				transform: translateY(-20px);
				opacity: 0;
				pointer-events: none;

				@include transition;
				transition-property: opacity, transform;

				li {
					text-align: left;
					margin: 0;
					padding: 5px 0;
					list-style: none;
					opacity: 0;
					transform: translateY(-20px);

					@include transition;
					transition-property: opacity, transform;

					@for $i from 1 through 10 {
						&:nth-of-type(#{$i}) {
							transition-delay: $i * 20ms;
						}
					}

					&.selected {
						position: relative;

						&:before {
							content: '';
							position: absolute;
							left: -20px;
							top: 15px;
							background-repeat: no-repeat;
							display: inline-block;
							height: 10px;
							width: 14px;
							background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='13.006' height='9.855' viewBox='0 0 13.006 9.855'><path id='Path' d='M20.037,9.1c-1.3.962.845-1.281-.318-.147-.947-.937-1.9-1.869-2.816-2.839a.739.739,0,0,0-.517-.163c-.258.038-.241.3-.129.607a9.207,9.207,0,0,0,1.9,2.874l1.085,1.185a.638.638,0,0,0,.9.043l.043-.043c1.774-1.6.21.139,1.833-1.627l.809-.731a63.753,63.753,0,0,0,4.848-4.9c.426-.492.672-1.013.362-1.253-.284-.218-.8-.081-1.3.359-.908.791-1.826,1.587-2.678,2.438C23.705,5.258,20.859,8.243,20.037,9.1Z' transform='translate(-15.681 -1.472)' fill='%23414648' stroke='%23414648' stroke-width='1'/></svg>");
						}
					}

					&:first-child {
						padding-top: 0;

						&.selected:before {
							top: 10px;
						}
					}

					&:last-child {
						padding-bottom: 0;
					}

					a {
						display: inline-block;
						width: 100%;
					}
				}
			}

			&.active {
				div.selectedLanguage:after {
					transform: rotateX(180deg);
				}

				ul.languages {
					opacity: 1;
					pointer-events: auto;
					transform: none;

					li {
						transform: none;
						opacity: 1;
					}
				}
			}
		}

		&.scrolled {
			background-color: white;

			a.logo svg {
				max-height: 50px;
				
				@include media-breakpoint-up(md) { // Added; Carlo 17-02-2020
					use {
						fill: $colorPrimaryGreyShade2;
					}
				}
			}

			div.search {
				margin: 10px 0;
			}

			div.languageSelection {
				margin: 10px 0;

				div.selectedLanguage {
					text-shadow: none;
				}
			}
		}
	}

	&:not(.homepage) {
		header {
			background-color: white;

			@include media-breakpoint-up(md) { //Added; Carlo 17*02*2020
				a.logo svg use {
					fill: $colorPrimaryGreyShade2;
				}
			}
		}
	}



	section.content {
		margin-top: 140px;
	}

	&.eventOverviewPage, &.locationOverviewPage, &.routeOverviewPage, &.searchResultsPage {
		section.content {
			margin-top: 180px;

			@include media-breakpoint-down(sm) {
				margin-top: 50px;
			}
		}
	}

	&.homepage {
		section.content {
			margin-top: 0px;

			@include media-breakpoint-down(sm) {
				margin-top: 50px;
			}
		}
	}

	div.interactivity {
		display: none;
	}

	div.search {
		margin: 30px 0;
		position: relative;
		flex: 0 0 360px;

		@include media-breakpoint-down(md) {
			flex-shrink: 1;
		}

		input {
			background-color: $colorPrimaryGreyTint1;
			width: 100%;
			height: 50px;
			border-radius: 5px;
			border: none;
			box-sizing: border-box;
			padding: 0 50px 0 20px;
			font-size: 18px;
			color: $colorText;
		}

		.icon_search {
			position: absolute;
			top: 0;
			right: 0;
			width: 50px;
			max-height: 50px;
			height: 50px;
			background-color: $colorPrimaryGrey;
			padding: 10px;
			border-radius: 0 5px 5px 0;
			cursor: pointer;

			use {
				fill: $colorIconGreenAccent1;
			}
		}
	}


	@include media-breakpoint-down(sm) {
		&:not(.homepage) {
			header {
				background-color: $colorPrimaryGreenShade1;
			}
		}

		header {
			background-color: $colorPrimaryGreenShade1;
			padding: 10px 0;
			position: fixed;
			top: 0;
			z-index: 10;

			div.col {
				justify-content: flex-end;
			}

			.menuItems, .search {
				margin: 0;
			}

			/*a.logo { // Dissabled; Carlo 17-02-2020
				display: none;
			}*/

			a.logo .logo_TID { // Added; Carlo 17-02-2020
				height: 25px;
				width: 125px;
			}

			div.search {
				display: none;
			}

			div.menuItems {
				position: fixed;
				top: 50px;
				left: 0;
				height: calc(100vh - 50px);
				width: 100vw;
				background-color: $colorPrimaryGreenShade1;
				transform: translateY(-100vh);
				padding: 0 50px;

				@include transition(transform);

				nav {
					margin-top: 30px;

					ul {
						flex-direction: column;
						justify-content: flex-start;
						align-items: flex-start;
						height: auto;

						li {
							padding: 10px 0;

							a {
								color: $colorPrimaryGreyTint1;
								@include fontSize(20px, 24px, 700);

								&.active {
									.menu_underline {
										display: none;
									}
								}
							}
						}

						&.submenu {
							margin-left: 15px;

							li {
								a {
									color: $colorPrimaryGrey;
									font-weight: 400;

									.themeIcon {
										max-width: 25px;
										opacity: 0.6;
									}
								}
							}
						}

						&.meta {
							li {
								a {
									color: $colorPrimaryGrey;
									font-weight: 400;
								}
							}
						}

						&.mobile {
							display: block;
						}

						&.languages {
							display: block;
							margin-top: 30px;
							margin-bottom: 20px;
							display: flex;
							flex-flow: wrap;
							align-items: center;
							justify-content: center;
							position: relative;

							li {
								padding: 0 5px;

								a {
									font-size: .8em;
									font-weight: 400;
									color: $colorPrimaryGreen;

									&.selected {
										text-decoration: underline;
									}
								}
							}
						}
					}
				}

				div.search {
					display: block;

					input {
						@include fontSize(14px, 18px);
					}

					.icon_search {
						background-color: transparent;
						padding: 15px;
					}
				}
			}

			div.interactivity {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				padding: 0 15px;

				.search {
					@include transition(opacity);
					opacity: 0.5;

					svg {
						width: 20px;
						max-height: 20px;

						use {
							fill: $colorPrimaryGrey;
						}
					}

					@include active() {
						opacity: 1;
					}
				}


				.menu {
					$animationDelay: 200ms;
					opacity: 0.5;
					display: inline-block;
					height: 22px;
					width: 24px;
					margin-left: 20px;
					@include transition(opacity);

					@include active() {
						opacity: 1;
					}

					svg {
						position: absolute;
						height: 22px;
						width: 24px;
						fill: $colorPrimaryGrey;

						g {
							path {
								@include transition(transform, $animationDelay);

								&:nth-of-type(1) {
									transform-origin: top left;
								}

								&:nth-of-type(3) {
									transform-origin: bottom right;
								}
							}
						}
					}

					&.active {
						svg {
							g {
								path {
									&:nth-of-type(1) {
										transition-delay: $animationDelay;
										transform: translateY(2px) translateX(5px) rotate(45deg);
									}

									&:nth-of-type(2) {
										transform: translateX(25px);
									}

									&:nth-of-type(3) {
										transition-delay: $animationDelay;
										transform: translateY(-19px) translateX(-2px) rotate(-45deg);
									}
								}
							}
						}
					}
					// Just because
					&.delay:not(.active) {
						svg {
							g {
								path:nth-of-type(2) {
									transition-delay: $animationDelay;
								}
							}
						}
					}
				}
			}

			&.mobileMenuActive {
				.menuItems {
					transform: translateY(0);
				}

				.interactivity {
					.search {
						opacity: 0
					}
				}
			}

			&.scrolled {
				background-color: $colorPrimaryGreenShade1;

				div.menuItems {
					margin: 0;
				}

				div.search {
					margin: 0;
				}
			}
		}



		section.content {
			margin-top: 50px;
		}
	}
}
