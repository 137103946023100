﻿/// <reference path="../../settings/variables" />
/// <reference path="../../settings/mixins" />

body.searchResultsPage {

	section.highlights {
		text-align: left;

		div.row {
			padding-bottom: 40px;
		}


		h1 {
			@include fontZilla();
			@include fontSize(45px, 50px, 700);
			text-transform: uppercase;
			letter-spacing: 0.05em;

			@include media-breakpoint-down(sm) {
				@include fontSize(25px, 25px);
			}
		}
	}
}
