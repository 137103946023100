﻿/// <reference path="../settings/variables" />
/// <reference path="../settings/mixins" />

svg {
	&.logo_TID {
		width: 100%;
		max-height: 67px;

		use {
			fill: #38393a
		}
	}

	&.icon_carousel_arrow {
		width: 20px;
		max-height: 34px;

		use {
			fill: #FFFFFF;
			stroke: #FFFFFF;
			opacity: 0.8;
		}

		@include active() {
			use {
				opacity: 1;
			}
		}

		&.down {
			transform: rotate(90deg);
		}

		&.previous {
			transform: rotate(180deg);
		}

		&.slick-disabled {
			use {
				opacity: 0.2;
			}
			// ew
			cursor: unset !important;
		}
	}

	&.icon_route, &.icon_home, &.icon_mail, &.icon_phone {
		width: 25px;
		max-height: 25px;

		use {
			fill: white;
		}
	}

	&.icon_marker {
		width: 35px;
		max-height: 48px;
	}

	&.icon_checkbox {
		width: 20px;
		max-height: 20px;

		use {
			stroke: grey;
			stroke-width: 2px;
			fill: transparent;
		}
	}

	&.icon_checkmark {
		width: 16px;
		max-height: 16px;

		use {
			stroke: green;
		}
	}

	&.icon_search {
		width: 43px;
		max-height: 43px;

		use {
			fill: $colorPrimaryGrey;
		}
	}

	&.icon_chapeau {
		width: 52px;
		max-height: 52px;
		position: relative;
		vertical-align: bottom;
		margin-right: 10px;
	}

	&.icon_timeline {
		width: 100px;
		height: 100px;
		position: relative;
		vertical-align: bottom;
	}

	&.icon_back, &.icon_forward {
		width: 15px;
		max-height: 22px;
	}

	&.icon_modern_drenthe {
		width: 70px;
		max-height: 70px;

		use {
			fill: white;
		}
	}

	&.button_bg {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
	}

	&.headerBlock {
		width: 180px;
		max-height: 180px;

		use {
			fill: $colorPrimaryGreyShade2;
		}
	}

	&.menu_underline {
		max-width: 100%;
		width: 80px;
		max-height: 8px;
		position: absolute;
		bottom: -15px;
		left: 0;
		right: 0;
		margin: 0 auto;

		use {
			fill: $colorPrimaryGreen;
		}
	}

	&.edge-header-top {
		width: 100%;
		max-height: 35px;
		position: absolute;
		display: block;
		top: -1px;
		left: 0;
		transform: scaleX(1.001);
		z-index: 1;

		use {
			fill: #FFFFFF;
		}
	}

	&.edge-header-bottom {
		min-width: 1440px;
		width: 100%;
		max-height: 35px;
		display: block;
		transform: scaleX(1.002) translateX(-50%);
		margin-left: calc(50% + 2px);


		use {
			fill: #FFFFFF;
		}
	}

	&.edge-subheader-bottom {
		min-width: 1440px;
		width: 100%;
		max-height: 35px;
		display: block;
		transform: scaleX(1.002) translateX(-50%);
		margin-left: calc(50% + 2px);

		use {
			fill: #FFFFFF;
		}
	}

	&.edge-section {
		min-width: 1440px;
		width: 100%;
		max-height: 35px;
		display: block;
		transform: scaleX(1.002) translateX(-50%);
		margin-left: calc(50% + 2px);


		use {
			fill: #FFFFFF;
		}
	}

	&.icon_list {
		width: 26px;
		max-height: 27px;
		margin-right: 20px;
		vertical-align: text-bottom;

		use {
			fill: $colorPrimaryGreyTint1;
		}
	}

	&.image_mask {
		display: block;
		width: 100%;
		height: 101%;
		position: absolute;
		top: 0;
		left: 0;
	}

	&.calendar_icon {
		width: 19px;
		max-height: 25px;
		margin-right: 9px;

		use {
			fill: black;
		}
	}

	&.marker_icon {
		width: 15px;
		max-height: 23px;
		margin-right: 9px;
	}

	&.route_icon {
		width: 27px;
		max-height: 24px;
		margin-right: 9px;
	}

	&.logo {
		width: 48px;
		max-height: 48px;

		&.prehistorie {
			use {
				fill: $colorHeritageLineYellow;
			}
		}

		&.boermarke {
			use {
				fill: $colorHeritageLineGreen;
			}
		}

		&.randvandrenthe {
			use {
				fill: $colorHeritageLineBrown;
			}
		}

		&.nieuwewerelden {
			use {
				fill: $colorHeritageLineBlue;
			}
		}

		&.beschavingsoffensief {
			use {
				fill: $colorHeritageLinePurple;
			}
		}

		&.moderndrenthe {
			use {
				fill: $colorHeritageLinePink;
			}
		}
	}

	&.icon_arrow {
		width: 14px;
		max-height: 27px;
		fill: $colorPrimaryBlue;
	}

	&.icon_map {
		width: 32px;
		max-height: 30px;
		fill: $colorPrimaryGreyShade2;
	}

	&.icon_listview {
		width: 28px;
		max-height: 28px;
		fill: $colorPrimaryGreyShade2;
	}

	&.button_background {
		fill: $colorPrimaryBlue;
		width: 271px;
		max-height: 71px;
	}
}
