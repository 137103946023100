﻿/// <reference path="../../settings/variables" />
/// <reference path="../../settings/mixins" />

body {

	&.eventOverviewPage, &.locationOverviewPage, &.routeOverviewPage {
		section.highlights {
			h1{
				@include fontZilla();
				@include fontSize(45px, 50px, 700);
				text-transform:uppercase;
				letter-spacing:0.05em;
			}
		}
	}
}
