﻿/// <reference path="../../settings/variables" />
/// <reference path="../../settings/mixins" />

#calendarFilter {
	.dateFilter {
		margin-bottom: 10px;
		padding: 0 10px;

		h3 {
			display: none;
			@include fontZilla();
			font-size: 20px;
			line-height: 20px;
			margin-bottom: 30px;

			@include media-breakpoint-down(sm) {
				display: block;
			}
		}

		ul {
			display: flex;
			list-style: none;
			margin: 0;
			padding: 0;
			justify-content: flex-start;

			@include media-breakpoint-down(sm) {
				flex-direction: column;
			}

			li {
				margin-right: 20px;


				@include media-breakpoint-down(sm) {
					margin-bottom: 20px;
					margin-right: 0;
				}

				label {
					position: relative;

					&#clear{
						display:none;

						&.show{
							display:block;
						}
					}

					.labelTitle {
						color: $colorPrimaryGreyShade1;
						@include fontMontserrat();
						font-size: 16px;
						margin-left: 32px;
						cursor: pointer;

						&.delete {
							color: $colorPrimaryRed;
						}
					}

					input[type="radio"] {
						display: none;
					}

					input[type="text"] {
						width: 0;
						opacity: 0;
						position: absolute;
					}

					.checkboxBg {
						background: $colorPrimaryGrey;
						border: 1px solid $colorPrimaryGreyInactive;
						border-radius: 50%;
						bottom: 7px;
						content: ' ';
						height: 20px;
						left: 0px;
						position: absolute;
						width: 20px;
						cursor: pointer;

						svg {
							opacity: 0;
							left: 50%;
							max-height: 12px;
							position: absolute;
							top: 50%;
							transform: translate(-50%,-50%);
							width: 12px;
							@include transition(all,200ms);
						}
					}

					input[type="radio"] {
						display: none;

						&:checked {
							& ~ .checkboxBg {
								svg {
									opacity: 1;
								}
							}

							& ~ .labelTitle {
								color: $colorPrimaryRed;
							}
						}
					}
				}
			}
		}
	}
}
