// Normalize.scss
@import 'normalize-scss/sass/normalize';
@include normalize();

// Bootstrap
$grid-breakpoints: (
	// Extra small screen / phone
	xs: 0,
	// Small screen / phone
	sm: 590px,
	// Medium screen / tablet
	md: 800px,
	// Large screen / desktop
	lg: 1140px,
	// Extra large screen / wide desktop
	xl: 1440px
);

$grid-columns:16;
$grid-gutter-width:0;

$container-max-widths: (
	sm: 799px,
	md: 1139px,
	lg: 1439px,
	xl: 1440px
);

@import 'bootstrap/scss/bootstrap';

// Slick Carousel
@import 'slick-carousel/slick/slick.scss';

// Selectric
/*@import 'selectric/public/selectric';*/

// jQuery UI
@import '../datepicker';