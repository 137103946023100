/// <reference path="../settings/variables" />
/// <reference path="../settings/mixins" />

@import './settings';
@import './components';
@import './pages';

body {
    @include fontPrimary(18px, 30px);
}

h1, h2, h3, h4,
.h1, .h2, .h3, .h4,
.title {
    @include fontSecondary();
}

h1, .h1 {
    @include fontSize(60px, 50px, 700);

    @include media-breakpoint-down(sm) {
        @include fontSize(25px, 25px);
        letter-spacing: 0.2em;
    }
}

h2, .h2 {
    @include fontSize(45px, 40px, 700);
}

h3, .h3 {
    color: $colorText;
    @include fontSize(35px, 30px, 700);
    text-transform: unset;
}

h4, .h4, .title {
    color: $colorText;
    @include fontSize(30px, 25px, 700);
}

a {
    color: $colorPrimaryBlue;

    .pageContent & {
        font-weight: bold;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &:hover {
        color: $colorPrimaryBlue;
    }
}

body.timeLinePage, body.storyPage {
    span.chapeau {
        font-size: 18px;
    }
}

.languagepicker {
    /*background-color: #FFF;*/
    display: inline-block;
    padding: 0;
    height: 40px;
    overflow: hidden;
    transition: all .3s ease;
    margin: 0 50px 10px 0;
    vertical-align: top;
    float: left;

    a {
        color: #000;
        text-decoration: none;

        li {
            display: block;
            padding: 0px 20px;
            line-height: 40px;

            &:hover {
                /*background-color: #EEE;*/
            }

            span.short {
                display: inline-block;
            }

            span.full {
                display: none;
            }
        }

        &:first-child li {
            border: none;
            /*background: #FFF !important;*/
        }
    }

    &:hover {
        height: 81px;

        a {
            li {
                span.short {
                    display: none;
                }

                span.full {
                    display: inline-block;
                }
            }
        }
    }
}



span.chapeau {
    @include fontPrimary(20px, 40px);
    font-weight: 700;
    text-transform: uppercase;
    color: $colorTitle;
    margin-bottom: 15px;
    display: block;

    @include media-breakpoint-down(sm) {
        @include fontSize(14px, 18px);
    }
}

a.back {
    @include fontPrimary(18px, 22px);
    color: $colorTitle;


    svg {
        position: relative;
        @include transition();
    }

    &:hover {
        text-decoration: none;

        svg {
            transform: translateX(-5px);
        }
    }
}

p.intro {
    @include fontSecondary(25px, 40px);
    color: $colorText;
    text-transform: unset;
    margin: 50px 0;

    @include media-breakpoint-down(md) {
        @include fontSize(20px, 30px);
    }
}

div.imageHolderUrh {
    width: 100%;
    height: 100%;
}

div.centered {
    display: flex;
    justify-content: center;
}


.takeTimelineBackgroundColor {
    background-color: $colorPrimaryGreyShade2;
}

.takeTimelineColor {
    color: $colorPrimaryGreyShade2;
}

svg.takeTimelineFill {
    use {
        fill: $colorPrimaryGreyShade2;
    }
}

.geel {
    .takeTimelineBackgroundColor {
        background-color: $colorHeritageLineYellow;
    }

    .takeTimelineColor {
        color: $colorHeritageLineYellow;
    }

    .takeTimelineFill {
        use {
            fill: $colorHeritageLineYellow;
        }
    }

    & > .takeTimelineFillFromPrevious {
        use {
            fill: $colorHeritageLineYellow;
        }
    }
}

.blauw {
    .takeTimelineBackgroundColor {
        background-color: $colorHeritageLineBlue;
    }

    .takeTimelineColor {
        color: $colorHeritageLineBlue;
    }

    .takeTimelineFill {
        use {
            fill: $colorHeritageLineBlue;
        }
    }

    & > .takeTimelineFillFromPrevious {
        use {
            fill: $colorHeritageLineBlue;
        }
    }
}

.groen {
    .takeTimelineBackgroundColor {
        background-color: $colorHeritageLineGreen;
    }

    .takeTimelineColor {
        color: $colorHeritageLineGreen;
    }

    .takeTimelineFill {
        use {
            fill: $colorHeritageLineGreen;
        }
    }

    & > .takeTimelineFillFromPrevious {
        use {
            fill: $colorHeritageLineGreen;
        }
    }
}

.paars {
    .takeTimelineBackgroundColor {
        background-color: $colorHeritageLinePurple;
    }

    .takeTimelineColor {
        color: $colorHeritageLinePurple;
    }

    .takeTimelineFill {
        use {
            fill: $colorHeritageLinePurple;
        }
    }

    & > .takeTimelineFillFromPrevious {
        use {
            fill: $colorHeritageLinePurple;
        }
    }
}

.roze {
    .takeTimelineBackgroundColor {
        background-color: $colorHeritageLinePink;
    }

    .takeTimelineColor {
        color: $colorHeritageLinePink;
    }

    .takeTimelineFill {
        use {
            fill: $colorHeritageLinePink;
        }
    }

    & > .takeTimelineFillFromPrevious {
        use {
            fill: $colorHeritageLinePink;
        }
    }
}

.bruin {
    .takeTimelineBackgroundColor {
        background-color: $colorHeritageLineBrown;
    }

    .takeTimelineColor {
        color: $colorHeritageLineBrown;
    }

    .takeTimelineFill {
        use {
            fill: $colorHeritageLineBrown;
        }
    }

    & > .takeTimelineFillFromPrevious {
        use {
            fill: $colorHeritageLineBrown;
        }
    }
}

body {
    div.svgHolder {
        position: absolute;
        width: 100%;
        overflow-x: hidden;

        &.edge-header-bottom, &.edge-subheader-bottom {
            top: -35px;
        }

        &.edge-section-top {
            top: -35px;
        }

        &.edge-section-bottom {
            bottom: 0;
        }
    }
}

o /*l, ul {
    margin: 0;
    margin-bottom: 20px;
    padding: 0 0 0 40px;

    > li {
        list-style: none;
        position: relative;
        padding: 10px;
        padding-left: 25px;

        &:before {
            position: absolute;
            left: 0;
            font-weight: 900;
            text-align: right;
        }
    }
}*/
/*@include media-breakpoint-up(md) {
    ol, ul {
        padding: 0 0 0 80px;

        > li {
            padding: 20px;
            padding-left: 50px;
        }
    }
}*/
ol {
    counter-reset: item;

    > li {
        &:before {
            content: counter(item) '.';
            counter-increment: item;
        }

        > ol {
            counter-reset: subitem;

            > li {
                &:before {
                    content: counter(subitem) '.';
                    counter-increment: subitem;
                }

                > ol {
                    counter-reset: subsubitem;

                    > li {
                        &:before {
                            content: counter(subsubitem) '.';
                            counter-increment: subsubitem;
                        }
                    }
                }
            }
        }
    }
}

img {
    max-width: 100%;
}

main {
}
// Columns with flex should get a flex display
.row {
    > .col,
    > [class*="col-"] {
        &.flex {
            display: flex;
        }
    }
}

@import './print';
