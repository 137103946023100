/// <reference path="../settings/variables" />
/// <reference path="../settings/mixins" />

#cookieBar {
	position: fixed;
	background: rgba(74, 74, 74, 0.95);
	right: 0;
	bottom: 0;
	left: 0;
	color: #ffffff;
	padding: 20px 0 30px 0;
	@include transition(transform 800ms);
	transform: translateY(100%);
	width: 100%;
	z-index: 9999;

	&.open {
		transform: translateY(0);
	}

	.inner {
		display: flex;
		flex-wrap: wrap;
		padding: 0 50px;

		@include media-breakpoint-down(md) {
			flex-direction: column;
		}

		span.title {
			display: block;
			font-size: 20px;
			font-weight: 700;
			line-height: 22px;
			flex-basis: 100%;
			color: white;
		}

		.text {
			text-align: left;
			color: #ffffff;
			margin-bottom: 0;
			display: none;
			flex-direction: column;
			justify-content: center;
			flex: 1;
			margin-right: 50px;
			font-size: 16px;
			line-height: 22px;

			@include media-breakpoint-up(md) {
				display: flex;
			}

			&.mobile {
				display: flex;
				margin-right: 0;

				@include media-breakpoint-up(md) {
					display: none;
				}
			}

			p {
				margin: 0;
			}

			a {
				color: #ffffff;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}

		.buttons {
			margin-top: 20px;


			@include media-breakpoint-up(md) {
				margin-top: 0;
			}

			a.button, button {
				cursor: pointer;
				width: 100%;
				max-width: 150px;
				text-align: center;
				font-weight: 700;
				padding: 5px;
				margin: 5px 5px 0 0;
				font-size: 0.9em;
				justify-content: center;
				color: $colorButtonText;

				@include transition();

				@include media-breakpoint-up(md) {
					margin: 5px 0;
					max-width: none;
					display: block;
				}

				@include media-breakpoint-up(lg) {
					font-size: 0.9em;
				}

				&.agreeCookies {
					background-color: $colorButtonVisual;

					@include active() {
						background-color: $colorButtonText;
						color: $colorButtonVisual;
					}
				}

				&.goConfigure {
					background-color: $colorButtonVisual;

					@include active() {
						background-color: $colorButtonText;
						color: $colorButtonVisual;
					}
				}
			}
		}
	}
}

.videoCookieMessage {
	background: #F5F5F5;
	text-align: center;
	border: 1px solid #CECECE;
	display: table;
	height: 100%;
	margin: -1px;
	width: 100%;

	.contentHolder {
		display: table-cell;
		vertical-align: middle;

		.inner {
			margin-left: auto;
			margin-right: auto;
			padding: 10px 0;
			width: 100%;

			@include media-breakpoint-up(md) {
				padding: 20px;
			}
		}
	}

	svg {
		display: none;
		height: 60px;
		margin-bottom: 13px;
		vertical-align: top;
		width: 60px;

		@include media-breakpoint-up(md) {
			display: inline-block;
		}
	}

	span.title {
		color: #4A4A4A;
		display: block;
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 8px;
		padding: 0 20px;
	}

	p {
		color: #4A4A4A;
		font-size: 13px;
		line-height: 20px;
		margin: 0 0 14px;
		padding: 0 20px;

		a {
			border-bottom: 0;
			color: #3388C2;
			padding-bottom: 0;
			text-decoration: underline;

			&:hover, &:active, &:focus {
				border-bottom: 0;
				text-decoration: none;
			}
		}
	}
}

#cookieConfigure {
	.checkboxButton {
		margin-left: 30px;
		display: inline-block;

		@include media-breakpoint-down(md) {
			margin-left: 0px;
		}


		label {
			display: block;
			margin-bottom: 10px;

			.icon_checkbox {
				position: relative;
			}

			.icon_checkmark {
				display: inline-block;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				stroke-dasharray: 130px 130px;
				stroke-dashoffset: 130px;
				@include transition(stroke-dashoffset);
			}



			span {
				display: inline-block;
				vertical-align: middle;
				margin-bottom: 13px;

				a {
					color: #4a8dc0;
					font-weight: 700;
				}
			}
		}

		input {
			display: none;

			&:checked {
				background-color: red;

				~ label .icon_checkbox {
					.icon_checkmark {
						stroke-dashoffset: 0;
					}
				}
			}

			&:disabled {
				~ label .icon_checkbox {

					use {
						stroke: gray;
					}

					.icon_checkmark {
						use {
							stroke: gray;
						}
					}
				}
			}
		}


		&.invalid {
			.icon_checkbox {
				fill: red;
			}
		}
	}

	.onSaveComplete {
		@include transition(opacity 200ms);
		margin-left: 20px;
		display: inline-block;
		opacity: 0;
		pointer-events: none;

		@include media-breakpoint-down(md) {
			margin-left: 0px;
		}

		&.success {
			opacity: 1;
		}
	}
}
