﻿/// <reference path="../settings/variables" />
/// <reference path="../settings/mixins" />

.itemPage {
	opacity: 0;
	height: 0;
	overflow-y: hidden;
	@include transition(all, 300ms);

	&.show {
		opacity: 1;
		height: auto;
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		justify-content: flex-start;
		width: 100%;

		@include media-breakpoint-down(lg) {
			justify-content: space-between;
		}
	}
}

.pagerContainer {
	margin-top: 25px;

	.pager {
		ul {
			align-content: center;
			list-style: none;
			margin: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0;

			li {
				margin-left: 10px;
				margin-right: 10px;

				@include media-breakpoint-down(sm) {
					display: none;
				}

				&.active {
					@include media-breakpoint-down(sm) {
						display: block;
					}

					a {
						color: $colorPrimaryGreyShade1;
						background: $colorPrimaryGrey;
						height: 60px;
						width: 60px;
						padding: 15px 0 0 0;
					}
				}

				&.first, &.last {
					@include media-breakpoint-down(sm) {
						display: block;
					}

					&.disabled {
						opacity:0.5;
						pointer-events:none;
					}
				}

				a {
					color: $colorPrimaryGreyInactive;
					background: $colorPrimaryGreyTint1;
					border-radius: 50%;
					display: block;
					@include fontMontserrat();
					font-size: 20px;
					height: 50px;
					padding: 11px 0 0 0;
					text-align: center;
					vertical-align: middle;
					width: 50px;

					&:hover {
						text-decoration: none;
						color: $colorPrimaryGreyShade1;
						background: $colorPrimaryGrey;
					}
				}
			}
		}
	}
}
