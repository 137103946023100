﻿/// <reference path="../../settings/variables" />
/// <reference path="../../settings/mixins" />

#mapview {
	position: relative;
	opacity: 0;
	height: 0;
	pointer-events: none;
	overflow:hidden;
	@include transition(all,400ms);

	&.show {
		opacity: 1;
		height: auto;
		pointer-events: all;
	}

	#map {
		height: 740px;
		width: 100%;
	}

	#mapContentItem {
		background: $colorPrimaryGreyTint1;
		position: absolute;
		right: 0;
		top: 0;
		opacity: 0;
		width: 332px;
		height: 100%;
		pointer-events: none;
		@include transition(all,300ms);

		@include media-breakpoint-down(sm) {
			width: 100%;
		}

		img {
			@include media-breakpoint-down(sm) {
				width:100%;
			}
		}

		&.show {
			opacity: 1;
			pointer-events: all;
		}

		a.close {
			position: absolute;
			top: 0;
			right: 0;
			height: 50px;
			width: 50px;
			background: $colorPrimaryGreyShade2;

			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				width: 25px;
				fill: #fff;

				g {
					path {
						&:nth-of-type(1) {
							transform: translateY(2px) translateX(5px) rotate(45deg);
						}

						&:nth-of-type(2) {
							transform: translateX(25px);
						}

						&:nth-of-type(3) {
							transform: translateY(6px) translateX(-10px) rotate(-45deg);
						}
					}
				}
			}
		}

		.contentItemText {
			padding: 35px 30px;

			.icon {
				display: block;
				position: relative;
				margin-top: -70px;
				width: 50px
			}

			.title {
				@include fontZilla();
				font-size: 30px;
				font-weight: normal;
				line-height: 30px;
				margin-top: 30px;
			}

			.address {
				margin-top: 10px;
				@include fontMontserrat();
				font-size: 16px;
				font-style: italic;
				line-height: 25px;
			}

			.link {
				display: block;
				@include fontZilla();
				font-size: 20px;
				color: $colorPrimaryBlue;
				margin-top: 25px;

				svg.icon_arrow {
					position: relative;
					top: -3px;
				}
			}
		}
	}
}
