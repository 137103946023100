﻿/// <reference path="../settings/variables" />
/// <reference path="../settings/mixins" />

body {

	section.experience {
		position: relative;
		text-align: center;
		padding-bottom: 50px;

		h2 {
			color: $colorText;
			margin-top: 50px;
			margin-bottom: 15px;
			text-transform: unset;

			@include media-breakpoint-down(sm) {
				font-size: 30px;
			}
		}

		p {
			@include fontZilla();
			@include fontSize(25px, 40px);
			color: $colorText;
			margin-bottom: 35px;

			@include media-breakpoint-down(sm) {
				font-size: 20px;
			}
		}
	}
}
