﻿/// <reference path="../../settings/variables" />
/// <reference path="../../settings/mixins" />

#calendarFilter {
	margin: 40px 0 0 0;

	@include media-breakpoint-down(md) {
		position: fixed;
		top: 30px;
		max-height: calc(100vh - 70px - 30px);
		max-height: calc(var(--fullHeight) - 70px - 30px);
		height: calc(var(--fullHeight) - 70px - 30px);
		overflow-y: auto;
		left: 5%;
		width: 90%;
		background-color: #ffffff;
		z-index: 3;
		padding: 26px 0 55px 30px;
		opacity: 0;
		pointer-events: none;
		@include transition(all, 0.3s);

		&.show {
			opacity: 1;
			pointer-events: all;
		}
	}

	.themeFilter {
		margin-bottom: 80px;

		@include media-breakpoint-down(md) {
			margin-bottom: 50px;
		}

		h3 {
			display: none;
			@include fontZilla();
			font-size: 20px;
			line-height: 20px;
			margin-bottom: 30px;

			@include media-breakpoint-down(md) {
				display: block;
			}
		}

		ul.themes {
			display: flex;
			justify-content: space-between;
			list-style: none;
			margin: 0;
			padding: 0 10px;

			@include media-breakpoint-down(md) {
				flex-direction: column;
			}

			li {

				@include media-breakpoint-down(md) {
					margin-bottom: 20px;
				}

				label {
					@include fontSecondary(18px, 22px);
					font-weight: bold;
					color: $colorPrimaryGreyShade2;
					position: relative;
					display: flex;
					align-items: center;
					cursor: pointer;


					svg {
						margin-right: 10px;

						use {
							fill: $colorPrimaryGreyInactive;
						}
					}

					.labelTitle {
						color: $colorPrimaryGreyInactive;
						max-width: 100px;
					}

					.checkboxBg {
						background: $colorPrimaryGrey;
						border: 1px solid $colorPrimaryGreyInactive;
						border-radius: 50%;
						bottom: -4px;
						content: ' ';
						height: 20px;
						left: 32px;
						position: absolute;
						width: 20px;

						svg {
							opacity: 0;
							left: 50%;
							max-height: 12px;
							position: absolute;
							top: 50%;
							transform: translate(-50%,-50%);
							width: 12px;
							@include transition(all,200ms);
						}
					}

					input[type="checkbox"] {
						display: none;

						&:checked {
							& ~ .checkboxBg {
								svg {
									opacity: 1;
								}
							}

							& ~ .labelTitle {
								color: $colorPrimaryGreyShade2;
							}

							& ~ svg.prehistorie {
								use {
									fill: $colorHeritageLineYellow;
								}
							}

							& ~ svg.boermarke {
								use {
									fill: $colorHeritageLineGreen;
								}
							}

							& ~ svg.randvandrenthe {
								use {
									fill: $colorHeritageLineBrown;
								}
							}

							& ~ svg.nieuwewerelden {
								use {
									fill: $colorHeritageLineBlue;
								}
							}

							& ~ svg.beschavingsoffensief {
								use {
									fill: $colorHeritageLinePurple;
								}
							}

							& ~ svg.moderndrenthe {
								use {
									fill: $colorHeritageLinePink;
								}
							}
						}
					}
				}
			}
		}
	}
}

.mobileButton {
	display: none;
	width: 90%;
	position: fixed;
	left: 5%;
	bottom: 30px;
	background: linear-gradient(to top, white, white 113px, rgba(255, 255, 255, 0));
	padding-bottom: 15px;
	padding-top: 80px;
	pointer-events: none;

	a.submit {
		display: none;
		position: relative;
		text-align: center;
		color: #ffffff;
		@include fontZilla();
		font-size: 20px;
		line-height: 24px;
		font-weight: bold;
		width: 271px;
		padding: 20px 0;
		margin: 0 auto;
		pointer-events: all;



		svg {
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
		}
	}
}

#calendarFilter.show {
	.mobileButton {
		@include media-breakpoint-down(md) {
			display: block;
		}

		a.submit {
			@include media-breakpoint-down(md) {
				display: block;
			}
		}
	}
}

.mobileBackground {
	background-color: rgba(74, 80, 66, 0.9);
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 2;
	top: 0;
	left: 0;
	display: none;

	@include media-breakpoint-down(md) {
		display: block;
		opacity: 0;
		pointer-events: none;
		@include transition(all, 0.3s);

		&.show {
			opacity: 1;
			pointer-events: all;
		}
	}
}
