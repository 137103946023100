﻿/// <reference path="../../settings/variables" />
/// <reference path="../../settings/mixins" />

.calendarNav {
	position: relative;
	padding: 60px 0 20px;

	ul {
		align-content: center;
		display: flex;
		justify-content: center;
		list-style: none;
		margin: 0 auto;
		padding: 0;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
			width: 70%;
		}

		li {
			display: block;
			margin-right: 30px;
			min-width: 240px;
			float: left;

			@include media-breakpoint-down(sm) {
				margin-right: 0px;
				margin-bottom: 10px;
			}

			a {
				background: $colorPrimaryGreyTint1;
				border: 2px $colorPrimaryGreyTint1 solid;
				border-radius: 5px;
				color: $colorPrimaryGreyInactive;
				display: block;
				@include fontMontserrat();
				font-size: 18px;
				font-weight: bold;
				padding: 19px 30px 19px 30px;
				position: relative;
				text-decoration: none;
				text-transform: uppercase;
				text-align: center;
				vertical-align: central;
				@include transition();

				svg {
					/*position: absolute;
					top: 22px;
					left: 40px;*/
					use {
						fill: $colorPrimaryGreyInactive;
						@include transition(fill);
					}
				}

				@include active(){
					border:2px $colorPrimaryGreyShade1 solid;
					color:$colorPrimaryGreyShade1;
					background-color:#ffffff;

					svg{
						use{
							fill:$colorPrimaryGreyShade1;
						}
					}
				}

				&.active {
					background: #ffffff;
					border: 2px $colorPrimaryGreyShade2 solid;
					color: $colorPrimaryGreyShade2;

					svg {
						/*position: absolute;
					top: 22px;
					left: 40px;*/
						use {
							fill: $colorPrimaryGreyShade2;
						}
					}
				}
			}
		}
	}

	.bgLine {
		position: absolute;
		left: 0;
		top: 35px;
		content: ' ';
		width: 100%;
		height: 3px;
		background: $colorPrimaryGrey;
		z-index: -1;

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
}
