@charset "UTF-8";
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

/*!
 * Bootstrap v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 590px;
  --breakpoint-md: 800px;
  --breakpoint-lg: 1140px;
  --breakpoint-xl: 1440px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 32px; }

h2, .h2 {
  font-size: 24px; }

h3, .h3 {
  font-size: 18.72px; }

h4, .h4 {
  font-size: 16px; }

h5, .h5 {
  font-size: 13.28px; }

h6, .h6 {
  font-size: 10.72px; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 590px) {
    .container {
      max-width: 799px; } }
  @media (min-width: 800px) {
    .container {
      max-width: 1139px; } }
  @media (min-width: 1140px) {
    .container {
      max-width: 1439px; } }
  @media (min-width: 1440px) {
    .container {
      max-width: 1440px; } }

.container-fluid {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0;
  padding-left: 0; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 6.25%;
  max-width: 6.25%; }

.col-2 {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.col-3 {
  flex: 0 0 18.75%;
  max-width: 18.75%; }

.col-4 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-5 {
  flex: 0 0 31.25%;
  max-width: 31.25%; }

.col-6 {
  flex: 0 0 37.5%;
  max-width: 37.5%; }

.col-7 {
  flex: 0 0 43.75%;
  max-width: 43.75%; }

.col-8 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-9 {
  flex: 0 0 56.25%;
  max-width: 56.25%; }

.col-10 {
  flex: 0 0 62.5%;
  max-width: 62.5%; }

.col-11 {
  flex: 0 0 68.75%;
  max-width: 68.75%; }

.col-12 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-13 {
  flex: 0 0 81.25%;
  max-width: 81.25%; }

.col-14 {
  flex: 0 0 87.5%;
  max-width: 87.5%; }

.col-15 {
  flex: 0 0 93.75%;
  max-width: 93.75%; }

.col-16 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 17; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.order-13 {
  order: 13; }

.order-14 {
  order: 14; }

.order-15 {
  order: 15; }

.order-16 {
  order: 16; }

.offset-1 {
  margin-left: 6.25%; }

.offset-2 {
  margin-left: 12.5%; }

.offset-3 {
  margin-left: 18.75%; }

.offset-4 {
  margin-left: 25%; }

.offset-5 {
  margin-left: 31.25%; }

.offset-6 {
  margin-left: 37.5%; }

.offset-7 {
  margin-left: 43.75%; }

.offset-8 {
  margin-left: 50%; }

.offset-9 {
  margin-left: 56.25%; }

.offset-10 {
  margin-left: 62.5%; }

.offset-11 {
  margin-left: 68.75%; }

.offset-12 {
  margin-left: 75%; }

.offset-13 {
  margin-left: 81.25%; }

.offset-14 {
  margin-left: 87.5%; }

.offset-15 {
  margin-left: 93.75%; }

@media (min-width: 590px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 6.25%;
    max-width: 6.25%; }
  .col-sm-2 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-sm-3 {
    flex: 0 0 18.75%;
    max-width: 18.75%; }
  .col-sm-4 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-5 {
    flex: 0 0 31.25%;
    max-width: 31.25%; }
  .col-sm-6 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-sm-7 {
    flex: 0 0 43.75%;
    max-width: 43.75%; }
  .col-sm-8 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-9 {
    flex: 0 0 56.25%;
    max-width: 56.25%; }
  .col-sm-10 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-sm-11 {
    flex: 0 0 68.75%;
    max-width: 68.75%; }
  .col-sm-12 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-13 {
    flex: 0 0 81.25%;
    max-width: 81.25%; }
  .col-sm-14 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-sm-15 {
    flex: 0 0 93.75%;
    max-width: 93.75%; }
  .col-sm-16 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 17; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .order-sm-13 {
    order: 13; }
  .order-sm-14 {
    order: 14; }
  .order-sm-15 {
    order: 15; }
  .order-sm-16 {
    order: 16; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 6.25%; }
  .offset-sm-2 {
    margin-left: 12.5%; }
  .offset-sm-3 {
    margin-left: 18.75%; }
  .offset-sm-4 {
    margin-left: 25%; }
  .offset-sm-5 {
    margin-left: 31.25%; }
  .offset-sm-6 {
    margin-left: 37.5%; }
  .offset-sm-7 {
    margin-left: 43.75%; }
  .offset-sm-8 {
    margin-left: 50%; }
  .offset-sm-9 {
    margin-left: 56.25%; }
  .offset-sm-10 {
    margin-left: 62.5%; }
  .offset-sm-11 {
    margin-left: 68.75%; }
  .offset-sm-12 {
    margin-left: 75%; }
  .offset-sm-13 {
    margin-left: 81.25%; }
  .offset-sm-14 {
    margin-left: 87.5%; }
  .offset-sm-15 {
    margin-left: 93.75%; } }

@media (min-width: 800px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 6.25%;
    max-width: 6.25%; }
  .col-md-2 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-md-3 {
    flex: 0 0 18.75%;
    max-width: 18.75%; }
  .col-md-4 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-5 {
    flex: 0 0 31.25%;
    max-width: 31.25%; }
  .col-md-6 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-md-7 {
    flex: 0 0 43.75%;
    max-width: 43.75%; }
  .col-md-8 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-9 {
    flex: 0 0 56.25%;
    max-width: 56.25%; }
  .col-md-10 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-md-11 {
    flex: 0 0 68.75%;
    max-width: 68.75%; }
  .col-md-12 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-13 {
    flex: 0 0 81.25%;
    max-width: 81.25%; }
  .col-md-14 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-md-15 {
    flex: 0 0 93.75%;
    max-width: 93.75%; }
  .col-md-16 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 17; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .order-md-13 {
    order: 13; }
  .order-md-14 {
    order: 14; }
  .order-md-15 {
    order: 15; }
  .order-md-16 {
    order: 16; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 6.25%; }
  .offset-md-2 {
    margin-left: 12.5%; }
  .offset-md-3 {
    margin-left: 18.75%; }
  .offset-md-4 {
    margin-left: 25%; }
  .offset-md-5 {
    margin-left: 31.25%; }
  .offset-md-6 {
    margin-left: 37.5%; }
  .offset-md-7 {
    margin-left: 43.75%; }
  .offset-md-8 {
    margin-left: 50%; }
  .offset-md-9 {
    margin-left: 56.25%; }
  .offset-md-10 {
    margin-left: 62.5%; }
  .offset-md-11 {
    margin-left: 68.75%; }
  .offset-md-12 {
    margin-left: 75%; }
  .offset-md-13 {
    margin-left: 81.25%; }
  .offset-md-14 {
    margin-left: 87.5%; }
  .offset-md-15 {
    margin-left: 93.75%; } }

@media (min-width: 1140px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 6.25%;
    max-width: 6.25%; }
  .col-lg-2 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-lg-3 {
    flex: 0 0 18.75%;
    max-width: 18.75%; }
  .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-5 {
    flex: 0 0 31.25%;
    max-width: 31.25%; }
  .col-lg-6 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-lg-7 {
    flex: 0 0 43.75%;
    max-width: 43.75%; }
  .col-lg-8 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-9 {
    flex: 0 0 56.25%;
    max-width: 56.25%; }
  .col-lg-10 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-lg-11 {
    flex: 0 0 68.75%;
    max-width: 68.75%; }
  .col-lg-12 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-13 {
    flex: 0 0 81.25%;
    max-width: 81.25%; }
  .col-lg-14 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-lg-15 {
    flex: 0 0 93.75%;
    max-width: 93.75%; }
  .col-lg-16 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 17; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .order-lg-13 {
    order: 13; }
  .order-lg-14 {
    order: 14; }
  .order-lg-15 {
    order: 15; }
  .order-lg-16 {
    order: 16; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 6.25%; }
  .offset-lg-2 {
    margin-left: 12.5%; }
  .offset-lg-3 {
    margin-left: 18.75%; }
  .offset-lg-4 {
    margin-left: 25%; }
  .offset-lg-5 {
    margin-left: 31.25%; }
  .offset-lg-6 {
    margin-left: 37.5%; }
  .offset-lg-7 {
    margin-left: 43.75%; }
  .offset-lg-8 {
    margin-left: 50%; }
  .offset-lg-9 {
    margin-left: 56.25%; }
  .offset-lg-10 {
    margin-left: 62.5%; }
  .offset-lg-11 {
    margin-left: 68.75%; }
  .offset-lg-12 {
    margin-left: 75%; }
  .offset-lg-13 {
    margin-left: 81.25%; }
  .offset-lg-14 {
    margin-left: 87.5%; }
  .offset-lg-15 {
    margin-left: 93.75%; } }

@media (min-width: 1440px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 6.25%;
    max-width: 6.25%; }
  .col-xl-2 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xl-3 {
    flex: 0 0 18.75%;
    max-width: 18.75%; }
  .col-xl-4 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-5 {
    flex: 0 0 31.25%;
    max-width: 31.25%; }
  .col-xl-6 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xl-7 {
    flex: 0 0 43.75%;
    max-width: 43.75%; }
  .col-xl-8 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-9 {
    flex: 0 0 56.25%;
    max-width: 56.25%; }
  .col-xl-10 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xl-11 {
    flex: 0 0 68.75%;
    max-width: 68.75%; }
  .col-xl-12 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-13 {
    flex: 0 0 81.25%;
    max-width: 81.25%; }
  .col-xl-14 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xl-15 {
    flex: 0 0 93.75%;
    max-width: 93.75%; }
  .col-xl-16 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 17; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .order-xl-13 {
    order: 13; }
  .order-xl-14 {
    order: 14; }
  .order-xl-15 {
    order: 15; }
  .order-xl-16 {
    order: 16; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 6.25%; }
  .offset-xl-2 {
    margin-left: 12.5%; }
  .offset-xl-3 {
    margin-left: 18.75%; }
  .offset-xl-4 {
    margin-left: 25%; }
  .offset-xl-5 {
    margin-left: 31.25%; }
  .offset-xl-6 {
    margin-left: 37.5%; }
  .offset-xl-7 {
    margin-left: 43.75%; }
  .offset-xl-8 {
    margin-left: 50%; }
  .offset-xl-9 {
    margin-left: 56.25%; }
  .offset-xl-10 {
    margin-left: 62.5%; }
  .offset-xl-11 {
    margin-left: 68.75%; }
  .offset-xl-12 {
    margin-left: 75%; }
  .offset-xl-13 {
    margin-left: 81.25%; }
  .offset-xl-14 {
    margin-left: 87.5%; }
  .offset-xl-15 {
    margin-left: 93.75%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 589.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 799.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1139.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1439.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: 3.4375rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") no-repeat center right 1.75rem/1.125rem 1.125rem; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: 3.4375rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") no-repeat center right 1.75rem/1.125rem 1.125rem; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 590px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 590px) {
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 800px) {
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1140px) {
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1440px) {
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropdown-menu-left {
  right: auto;
  left: 0; }

@media (min-width: 590px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; } }

@media (min-width: 800px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; } }

@media (min-width: 1140px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; } }

@media (min-width: 1440px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:first-child {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
  .dropdown-item:last-child {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px); }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(2.875rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.8125rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 589.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 590px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 799.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 800px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1139.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1140px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1439.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1440px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 0; }
  @media (min-width: 590px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: 0;
      margin-left: 0; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 0; }
  @media (min-width: 590px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 590px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card {
  overflow: hidden; }
  .accordion .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 590px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
      opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (0.5rem * 2));
    content: ""; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 590px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
    .modal-dialog-centered::before {
      height: calc(100vh - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1140px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1440px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media screen and (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 590px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 800px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1140px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1440px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-3by4::before {
  padding-top: 133.33333%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 590px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 800px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1140px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1440px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 590px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 800px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1140px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1440px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 590px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 800px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1140px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1440px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 590px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 800px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1140px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1440px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 1140px !important; }
  .container {
    min-width: 1140px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*@import 'selectric/public/selectric';*/
.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede; }

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5; }

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede; }

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6; }

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4; }

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc; }

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2); }

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none; }

/* -------------------------------------------------
    Datepicker cells
   ------------------------------------------------- */
.datepicker--cells {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.datepicker--cell {
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 32px;
  z-index: 1; }

.datepicker--cell.-focus- {
  background: #f0f0f0; }

.datepicker--cell.-current- {
  color: #4EB5E6; }

.datepicker--cell.-current-.-focus- {
  color: #4a4a4a; }

.datepicker--cell.-current-.-in-range- {
  color: #4EB5E6; }

.datepicker--cell.-in-range- {
  background: rgba(92, 196, 239, 0.1);
  color: #4a4a4a;
  border-radius: 0; }

.datepicker--cell.-in-range-.-focus- {
  background-color: rgba(92, 196, 239, 0.2); }

.datepicker--cell.-disabled- {
  cursor: default;
  color: #aeaeae; }

.datepicker--cell.-disabled-.-focus- {
  color: #aeaeae; }

.datepicker--cell.-disabled-.-in-range- {
  color: #a1a1a1; }

.datepicker--cell.-disabled-.-current-.-focus- {
  color: #aeaeae; }

.datepicker--cell.-range-from- {
  border: 1px solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 4px 0 0 4px; }

.datepicker--cell.-range-to- {
  border: 1px solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 0 4px 4px 0; }

.datepicker--cell.-range-from-.-range-to- {
  border-radius: 4px; }

.datepicker--cell.-selected- {
  color: #fff;
  border: none;
  background: #5cc4ef; }

.datepicker--cell.-selected-.-current- {
  color: #fff;
  background: #5cc4ef; }

.datepicker--cell.-selected-.-focus- {
  background: #45bced; }

.datepicker--cell:empty {
  cursor: default; }

.datepicker--days-names {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 8px 0 3px; }

.datepicker--day-name {
  color: #FF9A19;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: .8em; }

.datepicker--cell-day {
  width: 14.28571%; }

.datepicker--cells-months {
  height: 170px; }

.datepicker--cell-month {
  width: 33.33%;
  height: 25%; }

.datepicker--years {
  height: 170px; }

.datepicker--cells-years {
  height: 170px; }

.datepicker--cell-year {
  width: 25%;
  height: 33.33%; }

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede; }

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5; }

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede; }

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6; }

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4; }

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc; }

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2); }

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none; }

/* -------------------------------------------------
    Datepicker
   ------------------------------------------------- */
.datepickers-container {
  position: absolute;
  left: 0;
  top: 0; }

@media print {
  .datepickers-container {
    display: none; } }

.datepicker {
  background: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-sizing: content-box;
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: #4a4a4a;
  width: 250px;
  position: absolute;
  left: -100000px;
  opacity: 0;
  transition: opacity 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
  z-index: 100; }

.datepicker.-from-top- {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px); }

.datepicker.-from-right- {
  -webkit-transform: translateX(8px);
  transform: translateX(8px); }

.datepicker.-from-bottom- {
  -webkit-transform: translateY(8px);
  transform: translateY(8px); }

.datepicker.-from-left- {
  -webkit-transform: translateX(-8px);
  transform: translateX(-8px); }

.datepicker.active {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  transition: opacity 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease; }

.datepicker-inline .datepicker {
  border-color: #d7d7d7;
  box-shadow: none;
  position: static;
  left: auto;
  right: auto;
  opacity: 1;
  -webkit-transform: none;
  transform: none; }

.datepicker-inline .datepicker--pointer {
  display: none; }

.datepicker--content {
  box-sizing: content-box;
  padding: 4px; }

.-only-timepicker- .datepicker--content {
  display: none; }

.datepicker--pointer {
  position: absolute;
  background: #fff;
  border-top: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  width: 10px;
  height: 10px;
  z-index: -1; }

.-top-left- .datepicker--pointer, .-top-center- .datepicker--pointer, .-top-right- .datepicker--pointer {
  top: calc(100% - 4px);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg); }

.-right-top- .datepicker--pointer, .-right-center- .datepicker--pointer, .-right-bottom- .datepicker--pointer {
  right: calc(100% - 4px);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg); }

.-bottom-left- .datepicker--pointer, .-bottom-center- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
  bottom: calc(100% - 4px);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg); }

.-left-top- .datepicker--pointer, .-left-center- .datepicker--pointer, .-left-bottom- .datepicker--pointer {
  left: calc(100% - 4px);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.-top-left- .datepicker--pointer, .-bottom-left- .datepicker--pointer {
  left: 10px; }

.-top-right- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
  right: 10px; }

.-top-center- .datepicker--pointer, .-bottom-center- .datepicker--pointer {
  left: calc(50% - 10px / 2); }

.-left-top- .datepicker--pointer, .-right-top- .datepicker--pointer {
  top: 10px; }

.-left-bottom- .datepicker--pointer, .-right-bottom- .datepicker--pointer {
  bottom: 10px; }

.-left-center- .datepicker--pointer, .-right-center- .datepicker--pointer {
  top: calc(50% - 10px / 2); }

.datepicker--body {
  display: none; }

.datepicker--body.active {
  display: block; }

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede; }

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5; }

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede; }

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6; }

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4; }

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc; }

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2); }

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none; }

/* -------------------------------------------------
    Navigation
   ------------------------------------------------- */
.datepicker--nav {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  min-height: 32px;
  padding: 4px; }

.-only-timepicker- .datepicker--nav {
  display: none; }

.datepicker--nav-title,
.datepicker--nav-action {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.datepicker--nav-action {
  width: 32px;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.datepicker--nav-action:hover {
  background: #f0f0f0; }

.datepicker--nav-action.-disabled- {
  visibility: hidden; }

.datepicker--nav-action svg {
  width: 32px;
  height: 32px; }

.datepicker--nav-action path {
  fill: none;
  stroke: #9c9c9c;
  stroke-width: 2px; }

.datepicker--nav-title {
  border-radius: 4px;
  padding: 0 8px; }

.datepicker--nav-title i {
  font-style: normal;
  color: #9c9c9c;
  margin-left: 5px; }

.datepicker--nav-title:hover {
  background: #f0f0f0; }

.datepicker--nav-title.-disabled- {
  cursor: default;
  background: none; }

.datepicker--buttons {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 4px;
  border-top: 1px solid #efefef; }

.datepicker--button {
  color: #4EB5E6;
  cursor: pointer;
  border-radius: 4px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px; }

.datepicker--button:hover {
  color: #4a4a4a;
  background: #f0f0f0; }

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede; }

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5; }

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede; }

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6; }

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4; }

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc; }

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2); }

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none; }

/* -------------------------------------------------
    Timepicker
   ------------------------------------------------- */
.datepicker--time {
  border-top: 1px solid #efefef;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 4px;
  position: relative; }

.datepicker--time.-am-pm- .datepicker--time-sliders {
  -webkit-flex: 0 1 138px;
  -ms-flex: 0 1 138px;
  flex: 0 1 138px;
  max-width: 138px; }

.-only-timepicker- .datepicker--time {
  border-top: none; }

.datepicker--time-sliders {
  -webkit-flex: 0 1 153px;
  -ms-flex: 0 1 153px;
  flex: 0 1 153px;
  margin-right: 10px;
  max-width: 153px; }

.datepicker--time-label {
  display: none;
  font-size: 12px; }

.datepicker--time-current {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 14px;
  text-align: center;
  margin: 0 0 0 10px; }

.datepicker--time-current-colon {
  margin: 0 2px 3px;
  line-height: 1; }

.datepicker--time-current-hours,
.datepicker--time-current-minutes {
  line-height: 1;
  font-size: 19px;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  position: relative;
  z-index: 1; }

.datepicker--time-current-hours:after,
.datepicker--time-current-minutes:after {
  content: '';
  background: #f0f0f0;
  border-radius: 4px;
  position: absolute;
  left: -2px;
  top: -3px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  opacity: 0; }

.datepicker--time-current-hours.-focus-:after,
.datepicker--time-current-minutes.-focus-:after {
  opacity: 1; }

.datepicker--time-current-ampm {
  text-transform: uppercase;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  color: #9c9c9c;
  margin-left: 6px;
  font-size: 11px;
  margin-bottom: 1px; }

.datepicker--time-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 11px;
  height: 17px;
  background: linear-gradient(to right, #dedede, #dedede) left 50%/100% 1px no-repeat; }

.datepicker--time-row:first-child {
  margin-bottom: 4px; }

.datepicker--time-row input[type='range'] {
  background: none;
  cursor: pointer;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none; }

.datepicker--time-row input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; }

.datepicker--time-row input[type='range']::-ms-tooltip {
  display: none; }

.datepicker--time-row input[type='range']:hover::-webkit-slider-thumb {
  border-color: #b8b8b8; }

.datepicker--time-row input[type='range']:hover::-moz-range-thumb {
  border-color: #b8b8b8; }

.datepicker--time-row input[type='range']:hover::-ms-thumb {
  border-color: #b8b8b8; }

.datepicker--time-row input[type='range']:focus {
  outline: none; }

.datepicker--time-row input[type='range']:focus::-webkit-slider-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef; }

.datepicker--time-row input[type='range']:focus::-moz-range-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef; }

.datepicker--time-row input[type='range']:focus::-ms-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef; }

.datepicker--time-row input[type='range']::-webkit-slider-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  transition: background .2s; }

.datepicker--time-row input[type='range']::-moz-range-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  transition: background .2s; }

.datepicker--time-row input[type='range']::-ms-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  transition: background .2s; }

.datepicker--time-row input[type='range']::-webkit-slider-thumb {
  margin-top: -6px; }

.datepicker--time-row input[type='range']::-webkit-slider-runnable-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: transparent; }

.datepicker--time-row input[type='range']::-moz-range-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: transparent; }

.datepicker--time-row input[type='range']::-ms-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: transparent; }

.datepicker--time-row input[type='range']::-ms-fill-lower {
  background: transparent; }

.datepicker--time-row input[type='range']::-ms-fill-upper {
  background: transparent; }

.datepicker--time-row span {
  padding: 0 12px; }

.datepicker--time-icon {
  color: #9c9c9c;
  border: 1px solid;
  border-radius: 50%;
  font-size: 16px;
  position: relative;
  margin: 0 5px -1px 0;
  width: 1em;
  height: 1em; }

.datepicker--time-icon:after, .datepicker--time-icon:before {
  content: '';
  background: currentColor;
  position: absolute; }

.datepicker--time-icon:after {
  height: .4em;
  width: 1px;
  left: calc(50% - 1px);
  top: calc(50% + 1px);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%); }

.datepicker--time-icon:before {
  width: .4em;
  height: 1px;
  top: calc(50% + 1px);
  left: calc(50% - 1px); }

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede; }

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5; }

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede; }

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6; }

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4; }

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc; }

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2); }

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none; }

@font-face {
  font-family: "Lemon Tuesday";
  src: url("/fonts/Lemon Tuesday.otf"); }

@keyframes swatchTileAppear {
  from {
    opacity: 0;
    transform: translateY(-10px); }
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes rotateQuarter {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(90deg); } }

@keyframes rotateHalf {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(180deg); } }

@keyframes rotate {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(360deg); } }

@keyframes cartAnimate {
  0% {
    transform: rotate(0); }
  25% {
    transform: rotate(10deg); }
  50% {
    transform: rotate(-10deg); }
  75% {
    transform: rotate(10deg); }
  100% {
    transform: rotate(0); } }

svg.logo_TID {
  width: 100%;
  max-height: 67px; }
  svg.logo_TID use {
    fill: #38393a; }

svg.icon_carousel_arrow {
  width: 20px;
  max-height: 34px; }
  svg.icon_carousel_arrow use {
    fill: #FFFFFF;
    stroke: #FFFFFF;
    opacity: 0.8; }
  svg.icon_carousel_arrow:focus use, svg.icon_carousel_arrow:active use, svg.icon_carousel_arrow:hover use {
    opacity: 1; }
  svg.icon_carousel_arrow.down {
    transform: rotate(90deg); }
  svg.icon_carousel_arrow.previous {
    transform: rotate(180deg); }
  svg.icon_carousel_arrow.slick-disabled {
    cursor: unset !important; }
    svg.icon_carousel_arrow.slick-disabled use {
      opacity: 0.2; }

svg.icon_route, svg.icon_home, svg.icon_mail, svg.icon_phone {
  width: 25px;
  max-height: 25px; }
  svg.icon_route use, svg.icon_home use, svg.icon_mail use, svg.icon_phone use {
    fill: white; }

svg.icon_marker {
  width: 35px;
  max-height: 48px; }

svg.icon_checkbox {
  width: 20px;
  max-height: 20px; }
  svg.icon_checkbox use {
    stroke: grey;
    stroke-width: 2px;
    fill: transparent; }

svg.icon_checkmark {
  width: 16px;
  max-height: 16px; }
  svg.icon_checkmark use {
    stroke: green; }

svg.icon_search {
  width: 43px;
  max-height: 43px; }
  svg.icon_search use {
    fill: #E7E7E1; }

svg.icon_chapeau {
  width: 52px;
  max-height: 52px;
  position: relative;
  vertical-align: bottom;
  margin-right: 10px; }

svg.icon_timeline {
  width: 100px;
  height: 100px;
  position: relative;
  vertical-align: bottom; }

svg.icon_back, svg.icon_forward {
  width: 15px;
  max-height: 22px; }

svg.icon_modern_drenthe {
  width: 70px;
  max-height: 70px; }
  svg.icon_modern_drenthe use {
    fill: white; }

svg.button_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1; }

svg.headerBlock {
  width: 180px;
  max-height: 180px; }
  svg.headerBlock use {
    fill: #414648; }

svg.menu_underline {
  max-width: 100%;
  width: 80px;
  max-height: 8px;
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  margin: 0 auto; }
  svg.menu_underline use {
    fill: #8EBCAC; }

svg.edge-header-top {
  width: 100%;
  max-height: 35px;
  position: absolute;
  display: block;
  top: -1px;
  left: 0;
  transform: scaleX(1.001);
  z-index: 1; }
  svg.edge-header-top use {
    fill: #FFFFFF; }

svg.edge-header-bottom {
  min-width: 1440px;
  width: 100%;
  max-height: 35px;
  display: block;
  transform: scaleX(1.002) translateX(-50%);
  margin-left: calc(50% + 2px); }
  svg.edge-header-bottom use {
    fill: #FFFFFF; }

svg.edge-subheader-bottom {
  min-width: 1440px;
  width: 100%;
  max-height: 35px;
  display: block;
  transform: scaleX(1.002) translateX(-50%);
  margin-left: calc(50% + 2px); }
  svg.edge-subheader-bottom use {
    fill: #FFFFFF; }

svg.edge-section {
  min-width: 1440px;
  width: 100%;
  max-height: 35px;
  display: block;
  transform: scaleX(1.002) translateX(-50%);
  margin-left: calc(50% + 2px); }
  svg.edge-section use {
    fill: #FFFFFF; }

svg.icon_list {
  width: 26px;
  max-height: 27px;
  margin-right: 20px;
  vertical-align: text-bottom; }
  svg.icon_list use {
    fill: #F7F8F9; }

svg.image_mask {
  display: block;
  width: 100%;
  height: 101%;
  position: absolute;
  top: 0;
  left: 0; }

svg.calendar_icon {
  width: 19px;
  max-height: 25px;
  margin-right: 9px; }
  svg.calendar_icon use {
    fill: black; }

svg.marker_icon {
  width: 15px;
  max-height: 23px;
  margin-right: 9px; }

svg.route_icon {
  width: 27px;
  max-height: 24px;
  margin-right: 9px; }

svg.logo {
  width: 48px;
  max-height: 48px; }
  svg.logo.prehistorie use {
    fill: #CDA33B; }
  svg.logo.boermarke use {
    fill: #437343; }
  svg.logo.randvandrenthe use {
    fill: #7A4B2C; }
  svg.logo.nieuwewerelden use {
    fill: #5C8C99; }
  svg.logo.beschavingsoffensief use {
    fill: #995C7C; }
  svg.logo.moderndrenthe use {
    fill: #c2185E; }

svg.icon_arrow {
  width: 14px;
  max-height: 27px;
  fill: #7299A8; }

svg.icon_map {
  width: 32px;
  max-height: 30px;
  fill: #414648; }

svg.icon_listview {
  width: 28px;
  max-height: 28px;
  fill: #414648; }

svg.button_background {
  fill: #7299A8;
  width: 271px;
  max-height: 71px; }

/*@import './components/file';*/
body button, body .button {
  font-family: 'Zilla Slab', serif;
  font-size: 20px;
  color: #F7F8F9;
  line-height: 25px;
  /*text-transform: uppercase;*/
  text-transform: unset;
  display: inline-block;
  padding: 17px 70px; }
  body button.primary, body button.secondary, body .button.primary, body .button.secondary {
    color: #414648;
    border-radius: 3px; }
  body button.primary, body .button.primary {
    background-color: #E7E7E1;
    transition: all 400ms cubic-bezier(0.4, 0.02, 0.15, 1); }
    body button.primary:hover, body .button.primary:hover {
      color: #fff;
      text-decoration: underline; }
  body button.secondary, body .button.secondary {
    background-color: #FFFFFF;
    border: 1px solid #E7E7E1;
    font-weight: normal;
    text-decoration: none;
    transition: all 0.3s cubic-bezier(0.4, 0.02, 0.15, 1); }
    body button.secondary:hover, body .button.secondary:hover {
      text-decoration: underline;
      border-color: #414648; }
  body button.visual, body .button.visual {
    position: relative;
    padding: 17px 60px;
    color: #F7F8F9;
    font-weight: 700; }
    body button.visual.red svg use, body .button.visual.red svg use {
      fill: #DB2B27; }
    body button.visual.blue svg use, body .button.visual.blue svg use {
      fill: #7299A8; }

body section.experience {
  position: relative;
  text-align: center;
  padding-bottom: 50px; }
  body section.experience h2 {
    color: #414648;
    margin-top: 50px;
    margin-bottom: 15px;
    text-transform: unset; }
    @media (max-width: 799.98px) {
      body section.experience h2 {
        font-size: 30px; } }
  body section.experience p {
    font-family: 'Zilla Slab', serif;
    font-size: 25px;
    line-height: 40px;
    color: #414648;
    margin-bottom: 35px; }
    @media (max-width: 799.98px) {
      body section.experience p {
        font-size: 20px; } }

body section.discover {
  text-align: center;
  position: relative; }
  body section.discover h2 {
    font-family: "Lemon Tuesday", "Zilla Slab", serif;
    font-size: 60px;
    color: #F7F8F9;
    line-height: 89px;
    text-transform: unset;
    font-weight: 400;
    color: #414648;
    margin-top: 10px;
    margin-bottom: 15px;
    text-transform: unset; }
    @media (max-width: 799.98px) {
      body section.discover h2 {
        font-size: 30px; } }
  body section.discover div.svgHolder.grey svg use {
    fill: #E7E7E1; }
  body section.discover section.discoverIcons {
    position: relative;
    background-color: #E7E7E1;
    margin-top: 100px;
    padding-top: 30px; }
    body section.discover section.discoverIcons div.timelines {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: -40px; }
      @media (max-width: 799.98px) {
        body section.discover section.discoverIcons div.timelines {
          justify-content: space-evenly;
          padding: 0 20px; } }
      body section.discover section.discoverIcons div.timelines svg {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: -100px;
        transition: top 400ms cubic-bezier(0.4, 0.02, 0.15, 1); }
        body section.discover section.discoverIcons div.timelines svg image.image_sm {
          display: none; }
      body section.discover section.discoverIcons div.timelines a.timeline {
        width: 145px;
        margin-bottom: 120px;
        position: relative;
        color: #414648;
        font-family: 'Zilla Slab', serif;
        font-size: 20px;
        line-height: 20px;
        font-weight: bold;
        padding: 20px 0; }
        body section.discover section.discoverIcons div.timelines a.timeline:focus, body section.discover section.discoverIcons div.timelines a.timeline:active, body section.discover section.discoverIcons div.timelines a.timeline:hover {
          text-decoration: none; }
          body section.discover section.discoverIcons div.timelines a.timeline:focus svg, body section.discover section.discoverIcons div.timelines a.timeline:active svg, body section.discover section.discoverIcons div.timelines a.timeline:hover svg {
            top: -115px; }
        @media (max-width: 799.98px) {
          body section.discover section.discoverIcons div.timelines a.timeline {
            font-size: 16px;
            width: 100px;
            padding: 0; }
            body section.discover section.discoverIcons div.timelines a.timeline svg.icon {
              width: 75px; }
              body section.discover section.discoverIcons div.timelines a.timeline svg.icon image {
                display: none; }
                body section.discover section.discoverIcons div.timelines a.timeline svg.icon image.image_sm {
                  display: block; } }
        @media (max-width: 1139.98px) {
          body section.discover section.discoverIcons div.timelines a.timeline {
            flex-basis: 33%; } }
        @media (max-width: 589.98px) {
          body section.discover section.discoverIcons div.timelines a.timeline {
            flex-basis: 40%; } }

body.homepage header .menuItems nav ul li a {
  color: #fff;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3); }
  body.homepage header .menuItems nav ul li a .menu_underline use {
    fill: #fff; }

body.homepage header div.search input {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  transition: all 0.3s cubic-bezier(0.4, 0.02, 0.15, 1); }
  body.homepage header div.search input::-webkit-input-placeholder {
    color: #fff; }
  body.homepage header div.search input:focus {
    border: 1px solid #fff;
    color: #fff; }
    body.homepage header div.search input:focus ~ .icon_search {
      border-left: 1px solid #fff;
      fill: #fff;
      transition: all 0.3s cubic-bezier(0.4, 0.02, 0.15, 1); }
      body.homepage header div.search input:focus ~ .icon_search use {
        fill: #fff;
        transition: all 0.3s cubic-bezier(0.4, 0.02, 0.15, 1); }

body.homepage header div.search .icon_search {
  background-color: transparent;
  border-left: 1px solid rgba(255, 255, 255, 0.5); }
  body.homepage header div.search .icon_search use {
    fill: #fff; }

body.homepage header div.languageSelection div.selectedLanguage {
  color: #fff;
  transition: color 400ms cubic-bezier(0.4, 0.02, 0.15, 1);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3); }
  body.homepage header div.languageSelection div.selectedLanguage:after {
    text-shadow: none; }

@media (min-width: 800px) {
  body.homepage header.scrolled .menuItems nav ul li a {
    color: #414648;
    text-shadow: none; }
    body.homepage header.scrolled .menuItems nav ul li a .menu_underline use {
      fill: #8EBCAC; } }

body.homepage header.scrolled div.search input {
  background-color: #F7F8F9;
  color: #414648;
  border: none; }
  body.homepage header.scrolled div.search input::-webkit-input-placeholder {
    color: #414648; }
  body.homepage header.scrolled div.search input:focus {
    color: #414648; }
    body.homepage header.scrolled div.search input:focus ~ .icon_search use {
      fill: #99995C; }

body.homepage header.scrolled div.search .icon_search {
  background-color: #E7E7E1;
  border: none; }
  body.homepage header.scrolled div.search .icon_search use {
    fill: #99995C; }

body.homepage header.scrolled div.languageSelection div.selectedLanguage {
  color: #414648;
  text-shadow: none; }

body header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  transition: all 400ms cubic-bezier(0.4, 0.02, 0.15, 1); }
  body header .col {
    position: relative; }
  body header a.logo {
    display: flex;
    width: 100%;
    align-items: center; }
    body header a.logo svg {
      transition: all 400ms cubic-bezier(0.4, 0.02, 0.15, 1); }
      body header a.logo svg use {
        transition: fill 400ms cubic-bezier(0.4, 0.02, 0.15, 1);
        fill: white; }
  body header div.col {
    display: flex; }
  body header .menuItems {
    transition: all 400ms cubic-bezier(0.4, 0.02, 0.15, 1);
    width: 100%;
    overflow-y: auto; }
    @media (min-width: 800px) {
      body header .menuItems {
        overflow-y: hidden; } }
    body header .menuItems .search {
      display: none; }
    body header .menuItems nav {
      height: 100%; }
      body header .menuItems nav ul {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 0; }
        body header .menuItems nav ul li a {
          position: relative;
          text-decoration: none;
          color: #414648;
          font-size: 20px;
          font-weight: 500; }
          body header .menuItems nav ul li a .menu_underline {
            display: none; }
          body header .menuItems nav ul li a:focus, body header .menuItems nav ul li a:active, body header .menuItems nav ul li a:hover {
            text-decoration: none; }
            body header .menuItems nav ul li a:focus .menu_underline, body header .menuItems nav ul li a:active .menu_underline, body header .menuItems nav ul li a:hover .menu_underline {
              display: block; }
          body header .menuItems nav ul li a.active .menu_underline {
            display: block; }
        body header .menuItems nav ul.mobile {
          display: none; }
        body header .menuItems nav ul.languages {
          position: absolute;
          display: none; }
  body header div.search {
    transition: all 400ms cubic-bezier(0.4, 0.02, 0.15, 1); }
  body header div.languageSelection {
    transition: all 400ms cubic-bezier(0.4, 0.02, 0.15, 1);
    margin: 30px 0;
    height: 50px;
    text-align: center;
    position: relative;
    display: none; }
    @media (min-width: 800px) {
      body header div.languageSelection {
        display: block; } }
    body header div.languageSelection div.selectedLanguage {
      line-height: 50px;
      font-weight: 500;
      cursor: pointer; }
      body header div.languageSelection div.selectedLanguage:after {
        content: '\25bc';
        font-size: 0.5em;
        /* padding-bottom: 5px; */
        position: absolute;
        display: inline-block;
        padding-left: 3px;
        transition: transform 400ms cubic-bezier(0.4, 0.02, 0.15, 1);
        transform-origin: center; }
    body header div.languageSelection ul.languages {
      position: absolute;
      background: #fff;
      border: 1px solid #E7E7E1;
      top: 40px;
      right: 0;
      margin: 0;
      padding: 23px 30px 23px 40px;
      transform: translateY(-20px);
      opacity: 0;
      pointer-events: none;
      transition: all 400ms cubic-bezier(0.4, 0.02, 0.15, 1);
      transition-property: opacity, transform; }
      body header div.languageSelection ul.languages li {
        text-align: left;
        margin: 0;
        padding: 5px 0;
        list-style: none;
        opacity: 0;
        transform: translateY(-20px);
        transition: all 400ms cubic-bezier(0.4, 0.02, 0.15, 1);
        transition-property: opacity, transform; }
        body header div.languageSelection ul.languages li:nth-of-type(1) {
          transition-delay: 20ms; }
        body header div.languageSelection ul.languages li:nth-of-type(2) {
          transition-delay: 40ms; }
        body header div.languageSelection ul.languages li:nth-of-type(3) {
          transition-delay: 60ms; }
        body header div.languageSelection ul.languages li:nth-of-type(4) {
          transition-delay: 80ms; }
        body header div.languageSelection ul.languages li:nth-of-type(5) {
          transition-delay: 100ms; }
        body header div.languageSelection ul.languages li:nth-of-type(6) {
          transition-delay: 120ms; }
        body header div.languageSelection ul.languages li:nth-of-type(7) {
          transition-delay: 140ms; }
        body header div.languageSelection ul.languages li:nth-of-type(8) {
          transition-delay: 160ms; }
        body header div.languageSelection ul.languages li:nth-of-type(9) {
          transition-delay: 180ms; }
        body header div.languageSelection ul.languages li:nth-of-type(10) {
          transition-delay: 200ms; }
        body header div.languageSelection ul.languages li.selected {
          position: relative; }
          body header div.languageSelection ul.languages li.selected:before {
            content: '';
            position: absolute;
            left: -20px;
            top: 15px;
            background-repeat: no-repeat;
            display: inline-block;
            height: 10px;
            width: 14px;
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='13.006' height='9.855' viewBox='0 0 13.006 9.855'><path id='Path' d='M20.037,9.1c-1.3.962.845-1.281-.318-.147-.947-.937-1.9-1.869-2.816-2.839a.739.739,0,0,0-.517-.163c-.258.038-.241.3-.129.607a9.207,9.207,0,0,0,1.9,2.874l1.085,1.185a.638.638,0,0,0,.9.043l.043-.043c1.774-1.6.21.139,1.833-1.627l.809-.731a63.753,63.753,0,0,0,4.848-4.9c.426-.492.672-1.013.362-1.253-.284-.218-.8-.081-1.3.359-.908.791-1.826,1.587-2.678,2.438C23.705,5.258,20.859,8.243,20.037,9.1Z' transform='translate(-15.681 -1.472)' fill='%23414648' stroke='%23414648' stroke-width='1'/></svg>"); }
        body header div.languageSelection ul.languages li:first-child {
          padding-top: 0; }
          body header div.languageSelection ul.languages li:first-child.selected:before {
            top: 10px; }
        body header div.languageSelection ul.languages li:last-child {
          padding-bottom: 0; }
        body header div.languageSelection ul.languages li a {
          display: inline-block;
          width: 100%; }
    body header div.languageSelection.active div.selectedLanguage:after {
      transform: rotateX(180deg); }
    body header div.languageSelection.active ul.languages {
      opacity: 1;
      pointer-events: auto;
      transform: none; }
      body header div.languageSelection.active ul.languages li {
        transform: none;
        opacity: 1; }
  body header.scrolled {
    background-color: white; }
    body header.scrolled a.logo svg {
      max-height: 50px; }
      @media (min-width: 800px) {
        body header.scrolled a.logo svg use {
          fill: #414648; } }
    body header.scrolled div.search {
      margin: 10px 0; }
    body header.scrolled div.languageSelection {
      margin: 10px 0; }
      body header.scrolled div.languageSelection div.selectedLanguage {
        text-shadow: none; }

body:not(.homepage) header {
  background-color: white; }
  @media (min-width: 800px) {
    body:not(.homepage) header a.logo svg use {
      fill: #414648; } }

body section.content {
  margin-top: 140px; }

body.eventOverviewPage section.content, body.locationOverviewPage section.content, body.routeOverviewPage section.content, body.searchResultsPage section.content {
  margin-top: 180px; }
  @media (max-width: 799.98px) {
    body.eventOverviewPage section.content, body.locationOverviewPage section.content, body.routeOverviewPage section.content, body.searchResultsPage section.content {
      margin-top: 50px; } }

body.homepage section.content {
  margin-top: 0px; }
  @media (max-width: 799.98px) {
    body.homepage section.content {
      margin-top: 50px; } }

body div.interactivity {
  display: none; }

body div.search {
  margin: 30px 0;
  position: relative;
  flex: 0 0 360px; }
  @media (max-width: 1139.98px) {
    body div.search {
      flex-shrink: 1; } }
  body div.search input {
    background-color: #F7F8F9;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: none;
    box-sizing: border-box;
    padding: 0 50px 0 20px;
    font-size: 18px;
    color: #414648; }
  body div.search .icon_search {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    max-height: 50px;
    height: 50px;
    background-color: #E7E7E1;
    padding: 10px;
    border-radius: 0 5px 5px 0;
    cursor: pointer; }
    body div.search .icon_search use {
      fill: #99995C; }

@media (max-width: 799.98px) {
  body:not(.homepage) header {
    background-color: #4A5042; }
  body header {
    background-color: #4A5042;
    padding: 10px 0;
    position: fixed;
    top: 0;
    z-index: 10;
    /*a.logo { // Dissabled; Carlo 17-02-2020
				display: none;
			}*/ }
    body header div.col {
      justify-content: flex-end; }
    body header .menuItems, body header .search {
      margin: 0; }
    body header a.logo .logo_TID {
      height: 25px;
      width: 125px; }
    body header div.search {
      display: none; }
    body header div.menuItems {
      position: fixed;
      top: 50px;
      left: 0;
      height: calc(100vh - 50px);
      width: 100vw;
      background-color: #4A5042;
      transform: translateY(-100vh);
      padding: 0 50px;
      transition: transform 400ms cubic-bezier(0.4, 0.02, 0.15, 1); }
      body header div.menuItems nav {
        margin-top: 30px; }
        body header div.menuItems nav ul {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          height: auto; }
          body header div.menuItems nav ul li {
            padding: 10px 0; }
            body header div.menuItems nav ul li a {
              color: #F7F8F9;
              font-size: 20px;
              line-height: 24px;
              font-weight: 700; }
              body header div.menuItems nav ul li a.active .menu_underline {
                display: none; }
          body header div.menuItems nav ul.submenu {
            margin-left: 15px; }
            body header div.menuItems nav ul.submenu li a {
              color: #E7E7E1;
              font-weight: 400; }
              body header div.menuItems nav ul.submenu li a .themeIcon {
                max-width: 25px;
                opacity: 0.6; }
          body header div.menuItems nav ul.meta li a {
            color: #E7E7E1;
            font-weight: 400; }
          body header div.menuItems nav ul.mobile {
            display: block; }
          body header div.menuItems nav ul.languages {
            display: block;
            margin-top: 30px;
            margin-bottom: 20px;
            display: flex;
            flex-flow: wrap;
            align-items: center;
            justify-content: center;
            position: relative; }
            body header div.menuItems nav ul.languages li {
              padding: 0 5px; }
              body header div.menuItems nav ul.languages li a {
                font-size: .8em;
                font-weight: 400;
                color: #8EBCAC; }
                body header div.menuItems nav ul.languages li a.selected {
                  text-decoration: underline; }
      body header div.menuItems div.search {
        display: block; }
        body header div.menuItems div.search input {
          font-size: 14px;
          line-height: 18px; }
        body header div.menuItems div.search .icon_search {
          background-color: transparent;
          padding: 15px; }
    body header div.interactivity {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 15px; }
      body header div.interactivity .search {
        transition: opacity 400ms cubic-bezier(0.4, 0.02, 0.15, 1);
        opacity: 0.5; }
        body header div.interactivity .search svg {
          width: 20px;
          max-height: 20px; }
          body header div.interactivity .search svg use {
            fill: #E7E7E1; }
        body header div.interactivity .search:focus, body header div.interactivity .search:active, body header div.interactivity .search:hover {
          opacity: 1; }
      body header div.interactivity .menu {
        opacity: 0.5;
        display: inline-block;
        height: 22px;
        width: 24px;
        margin-left: 20px;
        transition: opacity 400ms cubic-bezier(0.4, 0.02, 0.15, 1); }
        body header div.interactivity .menu:focus, body header div.interactivity .menu:active, body header div.interactivity .menu:hover {
          opacity: 1; }
        body header div.interactivity .menu svg {
          position: absolute;
          height: 22px;
          width: 24px;
          fill: #E7E7E1; }
          body header div.interactivity .menu svg g path {
            transition: transform 200ms cubic-bezier(0.4, 0.02, 0.15, 1); }
            body header div.interactivity .menu svg g path:nth-of-type(1) {
              transform-origin: top left; }
            body header div.interactivity .menu svg g path:nth-of-type(3) {
              transform-origin: bottom right; }
        body header div.interactivity .menu.active svg g path:nth-of-type(1) {
          transition-delay: 200ms;
          transform: translateY(2px) translateX(5px) rotate(45deg); }
        body header div.interactivity .menu.active svg g path:nth-of-type(2) {
          transform: translateX(25px); }
        body header div.interactivity .menu.active svg g path:nth-of-type(3) {
          transition-delay: 200ms;
          transform: translateY(-19px) translateX(-2px) rotate(-45deg); }
        body header div.interactivity .menu.delay:not(.active) svg g path:nth-of-type(2) {
          transition-delay: 200ms; }
    body header.mobileMenuActive .menuItems {
      transform: translateY(0); }
    body header.mobileMenuActive .interactivity .search {
      opacity: 0; }
    body header.scrolled {
      background-color: #4A5042; }
      body header.scrolled div.menuItems {
        margin: 0; }
      body header.scrolled div.search {
        margin: 0; }
  body section.content {
    margin-top: 50px; } }

body.homepage .pageHeader.centered .textHolder {
  bottom: 120px; }
  @media (max-width: 799.98px) {
    body.homepage .pageHeader.centered .textHolder {
      bottom: 30px; } }

body.homepage .pageHeader.centered svg.icon_carousel_arrow {
  cursor: pointer;
  margin-top: 20px; }
  body.homepage .pageHeader.centered svg.icon_carousel_arrow use {
    fill: #fff; }

body.homepage .pageHeader .headerImage {
  height: 710px;
  overflow: hidden; }
  @media (max-width: 799.98px) {
    body.homepage .pageHeader .headerImage {
      height: 300px; } }
  body.homepage .pageHeader .headerImage .imageHolderUrh.mobile {
    display: none; }
    @media (max-width: 799.98px) {
      body.homepage .pageHeader .headerImage .imageHolderUrh.mobile {
        display: block; } }
  body.homepage .pageHeader .headerImage video {
    height: auto;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto; }
    @media (max-width: 799.98px) {
      body.homepage .pageHeader .headerImage video {
        display: none; } }

body .pageHeader .headerImage {
  height: 470px;
  width: 100%;
  overflow: hidden; }
  body .pageHeader .headerImage .imageHolderUrh {
    position: relative; }
    body .pageHeader .headerImage .imageHolderUrh .gradient {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.34));
      /*opacity: 0.4;*/ }
    body .pageHeader .headerImage .imageHolderUrh img {
      width: 100%; }

body .pageHeader .textHolder {
  position: absolute;
  bottom: 50px;
  left: 0;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  width: 100%; }
  body .pageHeader .textHolder h1 {
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 20px; }
  body .pageHeader .textHolder span.type {
    font-family: 'Montserrat', serif;
    font-size: 18px;
    color: #414648;
    line-height: 22px;
    font-weight: 700;
    text-transform: uppercase;
    color: #F7F8F9;
    margin-bottom: 15px;
    display: block; }
    body .pageHeader .textHolder span.type svg use {
      fill: white; }
    @media (max-width: 799.98px) {
      body .pageHeader .textHolder span.type {
        font-size: 14px;
        line-height: 18px; } }
  body .pageHeader .textHolder span.nextDate {
    font-size: 24px;
    line-height: 29px;
    color: #F7F8F9; }
    body .pageHeader .textHolder span.nextDate:before {
      content: '‒';
      margin-right: 10px; }

@media (max-width: 799.98px) {
  body .pageHeader .headerImage {
    height: 300px; }
  body .pageHeader .edge-header-top {
    display: none; } }

body .pageHeader.centered .textHolder {
  bottom: 70px;
  text-align: center; }
  body .pageHeader.centered .textHolder .chapeau {
    font-family: 'Montserrat', serif;
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
    text-transform: unset;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3); }

body .headerImage, body .subHeader {
  position: relative; }

body .subHeader {
  padding-bottom: 40px; }

body section.highlights {
  text-align: center;
  position: relative;
  padding-top: 40px; }
  body section.highlights.primaryBlue {
    background: #7299A8; }
    body section.highlights.primaryBlue .takeTimelineFill use {
      fill: #7299A8; }
  body section.highlights h2 {
    font-family: "Lemon Tuesday", "Zilla Slab", serif;
    font-size: 60px;
    color: #F7F8F9;
    line-height: 89px;
    text-transform: unset;
    font-weight: 400;
    margin-bottom: 15px; }
  body section.highlights .row {
    padding-bottom: 70px; }
  body section.highlights a.showAllLink {
    color: #F7F8F9; }
  @media (max-width: 799.98px) {
    body section.highlights h2 {
      font-size: 30px;
      line-height: 30px; }
    body section.highlights a.showAllLink {
      font-size: 14px;
      line-height: 18px; }
      body section.highlights a.showAllLink svg {
        vertical-align: middle; } }

body div.carousel {
  margin: 30px -45px; }
  @media (max-width: 799.98px) {
    body div.carousel {
      margin: 30px 0; } }
  body div.carousel a {
    position: relative;
    margin: 0 auto;
    max-width: 360px;
    height: 395px;
    display: block; }
    @media (max-width: 1139.98px) {
      body div.carousel a {
        height: 275px; } }
    @media (max-width: 799.98px) {
      body div.carousel a {
        height: 175px; } }
    body div.carousel a div.carouselImage {
      width: 100%;
      height: 100%; }
      body div.carousel a div.carouselImage img {
        max-height: 100%;
        width: 100%; }
      body div.carousel a div.carouselImage div.gradient {
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, black 100%); }
      body div.carousel a div.carouselImage div.textHolder {
        position: absolute;
        padding: 95px 50px;
        bottom: 0;
        width: 100%; }
        @media (max-width: 1139.98px) {
          body div.carousel a div.carouselImage div.textHolder {
            bottom: 15px; } }
        body div.carousel a div.carouselImage div.textHolder h3 {
          color: #F7F8F9;
          text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5); }
        body div.carousel a div.carouselImage div.textHolder p.shortIntro {
          color: #F7F8F9;
          text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
          max-height: 0;
          overflow: hidden;
          transition: max-height 800ms cubic-bezier(0.4, 0.02, 0.15, 1);
          font-size: 16px;
          line-height: 25px;
          margin: 0; }
      body div.carousel a div.carouselImage:focus div.textHolder p.shortIntro, body div.carousel a div.carouselImage:active div.textHolder p.shortIntro, body div.carousel a div.carouselImage:hover div.textHolder p.shortIntro {
        max-height: 100px; }
        @media (max-width: 799.98px) {
          body div.carousel a div.carouselImage:focus div.textHolder p.shortIntro, body div.carousel a div.carouselImage:active div.textHolder p.shortIntro, body div.carousel a div.carouselImage:hover div.textHolder p.shortIntro {
            max-height: 0px; } }
      @media (max-width: 1139.98px) {
        body div.carousel a div.carouselImage {
          width: 250px;
          height: 273px; } }
      @media (max-width: 799.98px) {
        body div.carousel a div.carouselImage {
          width: 160px;
          height: 175px; } }
  body div.carousel.slick-slider svg.slick-arrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2; }
    body div.carousel.slick-slider svg.slick-arrow.previous {
      transform: translateY(-50%) rotate(180deg);
      right: initial;
      left: 0; }
  @media (max-width: 1139.98px) {
    body div.carousel.slick-slider div.carouselImage {
      width: 250px;
      height: 273px;
      margin: 0 auto;
      position: relative; }
      body div.carousel.slick-slider div.carouselImage div.textHolder {
        padding: 30px 15px; }
        body div.carousel.slick-slider div.carouselImage div.textHolder h3 {
          font-size: 20px;
          line-height: 25px; } }
  @media (max-width: 799.98px) {
    body div.carousel.slick-slider div.carouselImage {
      width: 160px;
      height: 175px; } }

body .dots .slick-dots {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center; }
  body .dots .slick-dots li {
    padding: 0;
    margin: 5px 10px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #E7E7E1;
    opacity: 0.5;
    cursor: pointer; }
    body .dots .slick-dots li.slick-active {
      background-color: #F7F8F9;
      opacity: 1; }
    body .dots .slick-dots li:before {
      display: none; }
    body .dots .slick-dots li button {
      display: none; }

body section.timelineOverview div.timeline {
  position: relative;
  padding-bottom: 35px; }
  body section.timelineOverview div.timeline div.inner {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    body section.timelineOverview div.timeline div.inner div.imageHolder {
      max-width: 360px;
      width: 100%;
      height: 100%;
      position: relative;
      margin: 30px 30px; }
      @media (max-width: 1439.98px) {
        body section.timelineOverview div.timeline div.inner div.imageHolder {
          max-width: 300px; } }
    body section.timelineOverview div.timeline div.inner div.textHolder {
      max-width: 630px;
      margin: 30px 0; }
      body section.timelineOverview div.timeline div.inner div.textHolder p {
        margin: 25px 0; }
        @media (max-width: 799.98px) {
          body section.timelineOverview div.timeline div.inner div.textHolder p {
            font-size: 16px; } }
      body section.timelineOverview div.timeline div.inner div.textHolder a {
        font-family: 'Zilla Slab', serif;
        font-size: 22px;
        font-weight: bold;
        line-height: 27px;
        padding-right: 20px;
        position: relative; }
        body section.timelineOverview div.timeline div.inner div.textHolder a svg {
          right: 0;
          margin-left: 0px;
          transition: all 400ms cubic-bezier(0.4, 0.02, 0.15, 1); }
        body section.timelineOverview div.timeline div.inner div.textHolder a:hover {
          text-decoration: none; }
          body section.timelineOverview div.timeline div.inner div.textHolder a:hover svg {
            margin-left: 5px; }
  body section.timelineOverview div.timeline:nth-child(odd) {
    background-color: #F7F8F9; }
    body section.timelineOverview div.timeline:nth-child(odd) div.svgHolder svg use, body section.timelineOverview div.timeline:nth-child(odd) div.imageHolder svg use {
      fill: #F7F8F9; }
  body section.timelineOverview div.timeline:nth-child(even) {
    background-color: white; }
    body section.timelineOverview div.timeline:nth-child(even) div.svgHolder {
      transform: scaleX(-1); }
    body section.timelineOverview div.timeline:nth-child(even) div.inner {
      flex-direction: row-reverse; }
    body section.timelineOverview div.timeline:nth-child(even) div.svgHolder svg use, body section.timelineOverview div.timeline:nth-child(even) div.imageHolder svg use {
      fill: white; }
  @media (max-width: 799.98px) {
    body section.timelineOverview div.timeline div.inner {
      flex-direction: column; }
      body section.timelineOverview div.timeline div.inner div.imageHolder {
        max-width: calc(100% - 80px); }
      body section.timelineOverview div.timeline div.inner div.textHolder h2 {
        font-size: 35px;
        line-height: 40px; }
    body section.timelineOverview div.timeline:nth-child(even) div.inner {
      flex-direction: column; } }

body.homepage section.pageContent {
  padding-bottom: 0px; }

body section.pageContent {
  position: relative;
  padding-bottom: 100px; }

body section.readMore .content {
  overflow: hidden; }
  body section.readMore .content.animate {
    transition: max-height 800ms cubic-bezier(0.4, 0.02, 0.15, 1); }

body section.readMore .gradient {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  transform: translateY(-100%);
  background: linear-gradient(rgba(255, 255, 255, 0), white 70%); }

body section.readMore .options {
  display: none; }

body section.readMore.showMore .options {
  display: block; }

body .umb-grid .row {
  margin-bottom: 40px; }
  body .umb-grid .row.timeperiod h2, body .umb-grid .row.timeperiod p {
    font-family: 'Zilla Slab', serif;
    font-size: 30px;
    line-height: 36px;
    font-weight: 400;
    color: #414648;
    opacity: 0.5;
    margin: 0 0; }
  body .umb-grid .row h2, body .umb-grid .row h3 {
    margin-bottom: 20px; }
  body .umb-grid .row p .externalLink a {
    position: relative;
    padding: 0 23px 0 0; }
    body .umb-grid .row p .externalLink a:after {
      position: absolute;
      right: 0;
      top: 2px;
      width: 16px;
      height: 16px;
      content: ' ';
      background: url("/img/icons/content-externallink.png") no-repeat; }
  body .umb-grid .row p .locationLink a {
    position: relative;
    padding: 0 18px 0 0; }
    body .umb-grid .row p .locationLink a:after {
      position: absolute;
      right: 0;
      top: 1px;
      width: 13px;
      height: 18px;
      content: ' ';
      background: url("/img/icons/content_marker.png") no-repeat; }

body .umb-grid .streamer {
  position: relative;
  background: #F7F8F9;
  margin: 60px 0;
  padding: 75px 0; }
  body .umb-grid .streamer .row {
    margin-bottom: 0px; }
    body .umb-grid .streamer .row .col {
      margin: 0 auto; }
      body .umb-grid .streamer .row .col p {
        text-align: center;
        font-family: "Lemon Tuesday", "Zilla Slab", serif;
        font-size: 45px;
        line-height: 45px;
        padding: 0 30px; }
  body .umb-grid .streamer .svgHolder.edge-section-bottom {
    bottom: -30px;
    left: 0; }
    body .umb-grid .streamer .svgHolder.edge-section-bottom svg {
      transform: rotate(180deg);
      transform-origin: 25%; }
  body .umb-grid .streamer .svgHolder svg use {
    fill: #F7F8F9; }

body .umb-grid .contentImage {
  position: relative; }
  body .umb-grid .contentImage svg.image_mask_content {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
    transform: scaleX(1.001); }
  body .umb-grid .contentImage img {
    margin-bottom: 1px; }

body .umb-grid .contentimagelarge {
  margin: 45px 0; }

body section.additionalInformation h2 {
  color: #414648;
  font-size: 25px;
  line-height: 40px; }

body section.additionalInformation div.infoHolder {
  padding-right: 60px; }
  body section.additionalInformation div.infoHolder div.occurrenceRule, body section.additionalInformation div.infoHolder div.priceRule {
    padding: 10px 0;
    display: flex;
    border-top: 1px solid #CFD1D1; }
    body section.additionalInformation div.infoHolder div.occurrenceRule span, body section.additionalInformation div.infoHolder div.priceRule span {
      font-size: 16px;
      line-height: 20px; }
    body section.additionalInformation div.infoHolder div.occurrenceRule span.occurrence, body section.additionalInformation div.infoHolder div.occurrenceRule span.name, body section.additionalInformation div.infoHolder div.priceRule span.occurrence, body section.additionalInformation div.infoHolder div.priceRule span.name {
      flex: 0 1 250px; }
    body section.additionalInformation div.infoHolder div.occurrenceRule span.time, body section.additionalInformation div.infoHolder div.occurrenceRule span.value, body section.additionalInformation div.infoHolder div.priceRule span.time, body section.additionalInformation div.infoHolder div.priceRule span.value {
      white-space: nowrap;
      font-style: italic; }
    body section.additionalInformation div.infoHolder div.occurrenceRule:last-of-type, body section.additionalInformation div.infoHolder div.priceRule:last-of-type {
      border-bottom: 1px solid #CFD1D1; }
  body section.additionalInformation div.infoHolder p.description {
    font-size: 16px;
    line-height: 30px;
    font-style: italic;
    border-top: 1px solid #CFD1D1;
    flex-basis: 100%; }

body section.contactBlock {
  display: flex;
  background-color: #F7F8F9; }
  body section.contactBlock div.contactInfo {
    padding: 30px;
    flex-basis: 50%; }
    body section.contactBlock div.contactInfo h2 {
      font-size: 25px;
      line-height: 40px;
      color: #414648; }
    body section.contactBlock div.contactInfo span {
      display: block;
      margin-bottom: 15px; }
    body section.contactBlock div.contactInfo ul.contactButtons {
      list-style: none;
      display: flex;
      padding: 0;
      margin: 0; }
      body section.contactBlock div.contactInfo ul.contactButtons li {
        text-align: center; }
        body section.contactBlock div.contactInfo ul.contactButtons li a {
          text-decoration: none;
          color: #414648;
          position: relative;
          min-width: 60px;
          padding-top: 60px;
          margin-right: 10px;
          display: block;
          font-size: 14px;
          line-height: 20px; }
          body section.contactBlock div.contactInfo ul.contactButtons li a svg {
            position: absolute;
            top: 0;
            background-color: #7299A8;
            width: 60px;
            max-height: 60px;
            padding: 15px;
            left: 0;
            right: 0;
            margin: auto;
            transition: opacity 400ms cubic-bezier(0.4, 0.02, 0.15, 1); }
          body section.contactBlock div.contactInfo ul.contactButtons li a:focus svg, body section.contactBlock div.contactInfo ul.contactButtons li a:active svg, body section.contactBlock div.contactInfo ul.contactButtons li a:hover svg {
            opacity: 0.8; }
  body section.contactBlock div.mapsImage {
    position: relative;
    flex-basis: 50%;
    background-position: center; }
    body section.contactBlock div.mapsImage svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 48px;
      margin: auto; }

.itemPage {
  opacity: 0;
  height: 0;
  overflow-y: hidden;
  transition: all 300ms cubic-bezier(0.4, 0.02, 0.15, 1); }
  .itemPage.show {
    opacity: 1;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: flex-start;
    width: 100%; }
    @media (max-width: 1439.98px) {
      .itemPage.show {
        justify-content: space-between; } }

.pagerContainer {
  margin-top: 25px; }
  .pagerContainer .pager ul {
    align-content: center;
    list-style: none;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0; }
    .pagerContainer .pager ul li {
      margin-left: 10px;
      margin-right: 10px; }
      @media (max-width: 799.98px) {
        .pagerContainer .pager ul li {
          display: none; } }
      @media (max-width: 799.98px) {
        .pagerContainer .pager ul li.active {
          display: block; } }
      .pagerContainer .pager ul li.active a {
        color: #535C3E;
        background: #E7E7E1;
        height: 60px;
        width: 60px;
        padding: 15px 0 0 0; }
      @media (max-width: 799.98px) {
        .pagerContainer .pager ul li.first, .pagerContainer .pager ul li.last {
          display: block; } }
      .pagerContainer .pager ul li.first.disabled, .pagerContainer .pager ul li.last.disabled {
        opacity: 0.5;
        pointer-events: none; }
      .pagerContainer .pager ul li a {
        color: #AEB1B2;
        background: #F7F8F9;
        border-radius: 50%;
        display: block;
        font-family: 'Montserrat', serif;
        font-size: 20px;
        height: 50px;
        padding: 11px 0 0 0;
        text-align: center;
        vertical-align: middle;
        width: 50px; }
        .pagerContainer .pager ul li a:hover {
          text-decoration: none;
          color: #535C3E;
          background: #E7E7E1; }

body footer {
  margin-top: 30px; }
  body footer section.footerImages {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 799.98px) {
      body footer section.footerImages .swipe {
        width: 100%; } }
    body footer section.footerImages .swipe img {
      margin-right: -20%;
      max-height: 120px; }
      @media (max-width: 799.98px) {
        body footer section.footerImages .swipe img {
          max-height: none; } }
    body footer section.footerImages .logo {
      padding: 50px 80px; }
      body footer section.footerImages .logo img {
        max-height: 35px; }
    @media (max-width: 799.98px) {
      body footer section.footerImages {
        flex-direction: column-reverse; } }
  body footer section.footerLinks {
    background-color: #F7F8F9;
    padding: 15px 0;
    text-align: center; }
    body footer section.footerLinks ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0; }
      @media (max-width: 1139.98px) {
        body footer section.footerLinks ul {
          flex-direction: column; } }
      body footer section.footerLinks ul li a {
        color: #7299A8;
        font-size: 16px;
        line-height: 19px; }
      @media (min-width: 1140px) {
        body footer section.footerLinks ul li:not(:last-child):after {
          content: '—';
          margin: 0 7px;
          color: #7299A8; } }
      @media (max-width: 1139.98px) {
        body footer section.footerLinks ul.languages {
          margin-top: 40px;
          margin-bottom: 20px;
          display: flex;
          flex-direction: row;
          flex-flow: wrap; }
          body footer section.footerLinks ul.languages li {
            padding: 0 5px; }
            body footer section.footerLinks ul.languages li a {
              color: #8EBCAC; }
            body footer section.footerLinks ul.languages li.selected a {
              text-decoration: underline; } }

#cookieBar {
  position: fixed;
  background: rgba(74, 74, 74, 0.95);
  right: 0;
  bottom: 0;
  left: 0;
  color: #ffffff;
  padding: 20px 0 30px 0;
  transition: transform 800ms 400ms cubic-bezier(0.4, 0.02, 0.15, 1);
  transform: translateY(100%);
  width: 100%;
  z-index: 9999; }
  #cookieBar.open {
    transform: translateY(0); }
  #cookieBar .inner {
    display: flex;
    flex-wrap: wrap;
    padding: 0 50px; }
    @media (max-width: 1139.98px) {
      #cookieBar .inner {
        flex-direction: column; } }
    #cookieBar .inner span.title {
      display: block;
      font-size: 20px;
      font-weight: 700;
      line-height: 22px;
      flex-basis: 100%;
      color: white; }
    #cookieBar .inner .text {
      text-align: left;
      color: #ffffff;
      margin-bottom: 0;
      display: none;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      margin-right: 50px;
      font-size: 16px;
      line-height: 22px; }
      @media (min-width: 800px) {
        #cookieBar .inner .text {
          display: flex; } }
      #cookieBar .inner .text.mobile {
        display: flex;
        margin-right: 0; }
        @media (min-width: 800px) {
          #cookieBar .inner .text.mobile {
            display: none; } }
      #cookieBar .inner .text p {
        margin: 0; }
      #cookieBar .inner .text a {
        color: #ffffff;
        text-decoration: underline; }
        #cookieBar .inner .text a:hover {
          text-decoration: none; }
    #cookieBar .inner .buttons {
      margin-top: 20px; }
      @media (min-width: 800px) {
        #cookieBar .inner .buttons {
          margin-top: 0; } }
      #cookieBar .inner .buttons a.button, #cookieBar .inner .buttons button {
        cursor: pointer;
        width: 100%;
        max-width: 150px;
        text-align: center;
        font-weight: 700;
        padding: 5px;
        margin: 5px 5px 0 0;
        font-size: 0.9em;
        justify-content: center;
        color: #F7F8F9;
        transition: all 400ms cubic-bezier(0.4, 0.02, 0.15, 1); }
        @media (min-width: 800px) {
          #cookieBar .inner .buttons a.button, #cookieBar .inner .buttons button {
            margin: 5px 0;
            max-width: none;
            display: block; } }
        @media (min-width: 1140px) {
          #cookieBar .inner .buttons a.button, #cookieBar .inner .buttons button {
            font-size: 0.9em; } }
        #cookieBar .inner .buttons a.button.agreeCookies, #cookieBar .inner .buttons button.agreeCookies {
          background-color: #7299A8; }
          #cookieBar .inner .buttons a.button.agreeCookies:focus, #cookieBar .inner .buttons a.button.agreeCookies:active, #cookieBar .inner .buttons a.button.agreeCookies:hover, #cookieBar .inner .buttons button.agreeCookies:focus, #cookieBar .inner .buttons button.agreeCookies:active, #cookieBar .inner .buttons button.agreeCookies:hover {
            background-color: #F7F8F9;
            color: #7299A8; }
        #cookieBar .inner .buttons a.button.goConfigure, #cookieBar .inner .buttons button.goConfigure {
          background-color: #7299A8; }
          #cookieBar .inner .buttons a.button.goConfigure:focus, #cookieBar .inner .buttons a.button.goConfigure:active, #cookieBar .inner .buttons a.button.goConfigure:hover, #cookieBar .inner .buttons button.goConfigure:focus, #cookieBar .inner .buttons button.goConfigure:active, #cookieBar .inner .buttons button.goConfigure:hover {
            background-color: #F7F8F9;
            color: #7299A8; }

.videoCookieMessage {
  background: #F5F5F5;
  text-align: center;
  border: 1px solid #CECECE;
  display: table;
  height: 100%;
  margin: -1px;
  width: 100%; }
  .videoCookieMessage .contentHolder {
    display: table-cell;
    vertical-align: middle; }
    .videoCookieMessage .contentHolder .inner {
      margin-left: auto;
      margin-right: auto;
      padding: 10px 0;
      width: 100%; }
      @media (min-width: 800px) {
        .videoCookieMessage .contentHolder .inner {
          padding: 20px; } }
  .videoCookieMessage svg {
    display: none;
    height: 60px;
    margin-bottom: 13px;
    vertical-align: top;
    width: 60px; }
    @media (min-width: 800px) {
      .videoCookieMessage svg {
        display: inline-block; } }
  .videoCookieMessage span.title {
    color: #4A4A4A;
    display: block;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    padding: 0 20px; }
  .videoCookieMessage p {
    color: #4A4A4A;
    font-size: 13px;
    line-height: 20px;
    margin: 0 0 14px;
    padding: 0 20px; }
    .videoCookieMessage p a {
      border-bottom: 0;
      color: #3388C2;
      padding-bottom: 0;
      text-decoration: underline; }
      .videoCookieMessage p a:hover, .videoCookieMessage p a:active, .videoCookieMessage p a:focus {
        border-bottom: 0;
        text-decoration: none; }

#cookieConfigure .checkboxButton {
  margin-left: 30px;
  display: inline-block; }
  @media (max-width: 1139.98px) {
    #cookieConfigure .checkboxButton {
      margin-left: 0px; } }
  #cookieConfigure .checkboxButton label {
    display: block;
    margin-bottom: 10px; }
    #cookieConfigure .checkboxButton label .icon_checkbox {
      position: relative; }
    #cookieConfigure .checkboxButton label .icon_checkmark {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      stroke-dasharray: 130px 130px;
      stroke-dashoffset: 130px;
      transition: stroke-dashoffset 400ms cubic-bezier(0.4, 0.02, 0.15, 1); }
    #cookieConfigure .checkboxButton label span {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 13px; }
      #cookieConfigure .checkboxButton label span a {
        color: #4a8dc0;
        font-weight: 700; }
  #cookieConfigure .checkboxButton input {
    display: none; }
    #cookieConfigure .checkboxButton input:checked {
      background-color: red; }
      #cookieConfigure .checkboxButton input:checked ~ label .icon_checkbox .icon_checkmark {
        stroke-dashoffset: 0; }
    #cookieConfigure .checkboxButton input:disabled ~ label .icon_checkbox use {
      stroke: gray; }
    #cookieConfigure .checkboxButton input:disabled ~ label .icon_checkbox .icon_checkmark use {
      stroke: gray; }
  #cookieConfigure .checkboxButton.invalid .icon_checkbox {
    fill: red; }

#cookieConfigure .onSaveComplete {
  transition: opacity 200ms 400ms cubic-bezier(0.4, 0.02, 0.15, 1);
  margin-left: 20px;
  display: inline-block;
  opacity: 0;
  pointer-events: none; }
  @media (max-width: 1139.98px) {
    #cookieConfigure .onSaveComplete {
      margin-left: 0px; } }
  #cookieConfigure .onSaveComplete.success {
    opacity: 1; }

/*@import './pages/file';*/
.calendarNav {
  position: relative;
  padding: 60px 0 20px; }
  .calendarNav ul {
    align-content: center;
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0 auto;
    padding: 0; }
    @media (max-width: 799.98px) {
      .calendarNav ul {
        flex-direction: column;
        width: 70%; } }
    .calendarNav ul li {
      display: block;
      margin-right: 30px;
      min-width: 240px;
      float: left; }
      @media (max-width: 799.98px) {
        .calendarNav ul li {
          margin-right: 0px;
          margin-bottom: 10px; } }
      .calendarNav ul li a {
        background: #F7F8F9;
        border: 2px #F7F8F9 solid;
        border-radius: 5px;
        color: #AEB1B2;
        display: block;
        font-family: 'Montserrat', serif;
        font-size: 18px;
        font-weight: bold;
        padding: 19px 30px 19px 30px;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        vertical-align: central;
        transition: all 400ms cubic-bezier(0.4, 0.02, 0.15, 1); }
        .calendarNav ul li a svg {
          /*position: absolute;
					top: 22px;
					left: 40px;*/ }
          .calendarNav ul li a svg use {
            fill: #AEB1B2;
            transition: fill 400ms cubic-bezier(0.4, 0.02, 0.15, 1); }
        .calendarNav ul li a:focus, .calendarNav ul li a:active, .calendarNav ul li a:hover {
          border: 2px #535C3E solid;
          color: #535C3E;
          background-color: #ffffff; }
          .calendarNav ul li a:focus svg use, .calendarNav ul li a:active svg use, .calendarNav ul li a:hover svg use {
            fill: #535C3E; }
        .calendarNav ul li a.active {
          background: #ffffff;
          border: 2px #414648 solid;
          color: #414648; }
          .calendarNav ul li a.active svg {
            /*position: absolute;
					top: 22px;
					left: 40px;*/ }
            .calendarNav ul li a.active svg use {
              fill: #414648; }
  .calendarNav .bgLine {
    position: absolute;
    left: 0;
    top: 35px;
    content: ' ';
    width: 100%;
    height: 3px;
    background: #E7E7E1;
    z-index: -1; }
    @media (max-width: 799.98px) {
      .calendarNav .bgLine {
        display: none; } }

#listview {
  transition: all 300ms cubic-bezier(0.4, 0.02, 0.15, 1);
  opacity: 0;
  height: 0;
  pointer-events: none;
  overflow: hidden; }
  body.storiesOverviewPage #listview {
    opacity: 1;
    height: auto;
    pointer-events: all; }
  #listview.show {
    opacity: 1;
    height: auto;
    pointer-events: all; }
  #listview.fade {
    opacity: 0; }
  #listview ul.listBlockView {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: flex-start;
    margin: -10px;
    padding: 0 10px; }
    @media (max-width: 1439.98px) {
      #listview ul.listBlockView {
        justify-content: space-between; } }
    @media (max-width: 799.98px) {
      #listview ul.listBlockView {
        padding: 0; } }
    #listview ul.listBlockView li {
      background: #F7F8F9;
      width: calc(33% - 20px);
      margin: 10px;
      position: relative;
      transition: all 0.5s cubic-bezier(0.4, 0.02, 0.15, 1); }
      #listview ul.listBlockView li * {
        transition: all 0.5s cubic-bezier(0.4, 0.02, 0.15, 1); }
      #listview ul.listBlockView li:focus, #listview ul.listBlockView li:active, #listview ul.listBlockView li:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }
        #listview ul.listBlockView li:focus a .imgHolder img, #listview ul.listBlockView li:active a .imgHolder img, #listview ul.listBlockView li:hover a .imgHolder img {
          transform: scale(1.05); }
      @media (max-width: 1439.98px) {
        #listview ul.listBlockView li {
          width: calc(33% - 20px); } }
      @media (max-width: 1139.98px) {
        #listview ul.listBlockView li {
          width: calc(50% - 20px); } }
      @media (max-width: 799.98px) {
        #listview ul.listBlockView li {
          width: calc(100% - 20px); } }
      #listview ul.listBlockView li.hide {
        display: none; }
      #listview ul.listBlockView li a {
        text-decoration: none; }
        #listview ul.listBlockView li a .imgHolder {
          overflow: hidden; }
        #listview ul.listBlockView li a .icons {
          position: relative;
          margin-top: -52px; }
        #listview ul.listBlockView li a .date {
          position: absolute;
          top: 20px;
          left: 30px;
          font-family: 'Zilla Slab', serif;
          font-size: 22px;
          color: #F7F8F9;
          line-height: 22px;
          /*text-transform: uppercase;*/
          font-weight: bold;
          text-shadow: 2px 2px 2px #000000;
          z-index: 2; }
        #listview ul.listBlockView li a .listItemContent {
          padding: 20px 30px; }
          #listview ul.listBlockView li a .listItemContent .title {
            font-family: 'Zilla Slab', serif;
            font-weight: normal;
            font-size: 30px;
            line-height: 30px;
            display: block;
            margin-top: 20px;
            margin-bottom: 5px; }
          #listview ul.listBlockView li a .listItemContent p {
            font-family: 'Montserrat', serif;
            font-size: 16px;
            line-height: 25px;
            color: #535C3E; }

#mapview {
  position: relative;
  opacity: 0;
  height: 0;
  pointer-events: none;
  overflow: hidden;
  transition: all 400ms cubic-bezier(0.4, 0.02, 0.15, 1); }
  #mapview.show {
    opacity: 1;
    height: auto;
    pointer-events: all; }
  #mapview #map {
    height: 740px;
    width: 100%; }
  #mapview #mapContentItem {
    background: #F7F8F9;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    width: 332px;
    height: 100%;
    pointer-events: none;
    transition: all 300ms cubic-bezier(0.4, 0.02, 0.15, 1); }
    @media (max-width: 799.98px) {
      #mapview #mapContentItem {
        width: 100%; } }
    @media (max-width: 799.98px) {
      #mapview #mapContentItem img {
        width: 100%; } }
    #mapview #mapContentItem.show {
      opacity: 1;
      pointer-events: all; }
    #mapview #mapContentItem a.close {
      position: absolute;
      top: 0;
      right: 0;
      height: 50px;
      width: 50px;
      background: #414648; }
      #mapview #mapContentItem a.close svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 25px;
        fill: #fff; }
        #mapview #mapContentItem a.close svg g path:nth-of-type(1) {
          transform: translateY(2px) translateX(5px) rotate(45deg); }
        #mapview #mapContentItem a.close svg g path:nth-of-type(2) {
          transform: translateX(25px); }
        #mapview #mapContentItem a.close svg g path:nth-of-type(3) {
          transform: translateY(6px) translateX(-10px) rotate(-45deg); }
    #mapview #mapContentItem .contentItemText {
      padding: 35px 30px; }
      #mapview #mapContentItem .contentItemText .icon {
        display: block;
        position: relative;
        margin-top: -70px;
        width: 50px; }
      #mapview #mapContentItem .contentItemText .title {
        font-family: 'Zilla Slab', serif;
        font-size: 30px;
        font-weight: normal;
        line-height: 30px;
        margin-top: 30px; }
      #mapview #mapContentItem .contentItemText .address {
        margin-top: 10px;
        font-family: 'Montserrat', serif;
        font-size: 16px;
        font-style: italic;
        line-height: 25px; }
      #mapview #mapContentItem .contentItemText .link {
        display: block;
        font-family: 'Zilla Slab', serif;
        font-size: 20px;
        color: #7299A8;
        margin-top: 25px; }
        #mapview #mapContentItem .contentItemText .link svg.icon_arrow {
          position: relative;
          top: -3px; }

#calendarFilter {
  margin: 40px 0 0 0; }
  @media (max-width: 1139.98px) {
    #calendarFilter {
      position: fixed;
      top: 30px;
      max-height: calc(100vh - 70px - 30px);
      max-height: calc(var(--fullHeight) - 70px - 30px);
      height: calc(var(--fullHeight) - 70px - 30px);
      overflow-y: auto;
      left: 5%;
      width: 90%;
      background-color: #ffffff;
      z-index: 3;
      padding: 26px 0 55px 30px;
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s cubic-bezier(0.4, 0.02, 0.15, 1); }
      #calendarFilter.show {
        opacity: 1;
        pointer-events: all; } }
  #calendarFilter .themeFilter {
    margin-bottom: 80px; }
    @media (max-width: 1139.98px) {
      #calendarFilter .themeFilter {
        margin-bottom: 50px; } }
    #calendarFilter .themeFilter h3 {
      display: none;
      font-family: 'Zilla Slab', serif;
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 30px; }
      @media (max-width: 1139.98px) {
        #calendarFilter .themeFilter h3 {
          display: block; } }
    #calendarFilter .themeFilter ul.themes {
      display: flex;
      justify-content: space-between;
      list-style: none;
      margin: 0;
      padding: 0 10px; }
      @media (max-width: 1139.98px) {
        #calendarFilter .themeFilter ul.themes {
          flex-direction: column; } }
      @media (max-width: 1139.98px) {
        #calendarFilter .themeFilter ul.themes li {
          margin-bottom: 20px; } }
      #calendarFilter .themeFilter ul.themes li label {
        font-family: 'Zilla Slab', serif;
        font-size: 18px;
        color: #F7F8F9;
        line-height: 22px;
        /*text-transform: uppercase;*/
        font-weight: bold;
        color: #414648;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer; }
        #calendarFilter .themeFilter ul.themes li label svg {
          margin-right: 10px; }
          #calendarFilter .themeFilter ul.themes li label svg use {
            fill: #AEB1B2; }
        #calendarFilter .themeFilter ul.themes li label .labelTitle {
          color: #AEB1B2;
          max-width: 100px; }
        #calendarFilter .themeFilter ul.themes li label .checkboxBg {
          background: #E7E7E1;
          border: 1px solid #AEB1B2;
          border-radius: 50%;
          bottom: -4px;
          content: ' ';
          height: 20px;
          left: 32px;
          position: absolute;
          width: 20px; }
          #calendarFilter .themeFilter ul.themes li label .checkboxBg svg {
            opacity: 0;
            left: 50%;
            max-height: 12px;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 12px;
            transition: all 200ms cubic-bezier(0.4, 0.02, 0.15, 1); }
        #calendarFilter .themeFilter ul.themes li label input[type="checkbox"] {
          display: none; }
          #calendarFilter .themeFilter ul.themes li label input[type="checkbox"]:checked ~ .checkboxBg svg {
            opacity: 1; }
          #calendarFilter .themeFilter ul.themes li label input[type="checkbox"]:checked ~ .labelTitle {
            color: #414648; }
          #calendarFilter .themeFilter ul.themes li label input[type="checkbox"]:checked ~ svg.prehistorie use {
            fill: #CDA33B; }
          #calendarFilter .themeFilter ul.themes li label input[type="checkbox"]:checked ~ svg.boermarke use {
            fill: #437343; }
          #calendarFilter .themeFilter ul.themes li label input[type="checkbox"]:checked ~ svg.randvandrenthe use {
            fill: #7A4B2C; }
          #calendarFilter .themeFilter ul.themes li label input[type="checkbox"]:checked ~ svg.nieuwewerelden use {
            fill: #5C8C99; }
          #calendarFilter .themeFilter ul.themes li label input[type="checkbox"]:checked ~ svg.beschavingsoffensief use {
            fill: #995C7C; }
          #calendarFilter .themeFilter ul.themes li label input[type="checkbox"]:checked ~ svg.moderndrenthe use {
            fill: #c2185E; }

.mobileButton {
  display: none;
  width: 90%;
  position: fixed;
  left: 5%;
  bottom: 30px;
  background: linear-gradient(to top, white, white 113px, rgba(255, 255, 255, 0));
  padding-bottom: 15px;
  padding-top: 80px;
  pointer-events: none; }
  .mobileButton a.submit {
    display: none;
    position: relative;
    text-align: center;
    color: #ffffff;
    font-family: 'Zilla Slab', serif;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    width: 271px;
    padding: 20px 0;
    margin: 0 auto;
    pointer-events: all; }
    .mobileButton a.submit svg {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1; }

@media (max-width: 1139.98px) {
  #calendarFilter.show .mobileButton {
    display: block; } }

@media (max-width: 1139.98px) {
  #calendarFilter.show .mobileButton a.submit {
    display: block; } }

.mobileBackground {
  background-color: rgba(74, 80, 66, 0.9);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  display: none; }
  @media (max-width: 1139.98px) {
    .mobileBackground {
      display: block;
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s cubic-bezier(0.4, 0.02, 0.15, 1); }
      .mobileBackground.show {
        opacity: 1;
        pointer-events: all; } }

#viewTools {
  position: relative; }
  @media (max-width: 1139.98px) {
    #viewTools {
      padding: 55px 0 0 0; } }
  #viewTools .mobileFilterToggle {
    display: none; }
    @media (max-width: 1139.98px) {
      #viewTools .mobileFilterToggle {
        display: block;
        position: absolute;
        left: 15%;
        bottom: 17px;
        z-index: 2; } }
    #viewTools .mobileFilterToggle a {
      font-family: 'Montserrat', serif;
      font-size: 16px;
      color: #535C3E;
      text-decoration: none; }
      #viewTools .mobileFilterToggle a:hover {
        text-decoration: underline; }
  #viewTools .toggle {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    z-index: 2; }
    @media (max-width: 1139.98px) {
      #viewTools .toggle {
        padding-right: 0;
        right: 15%; } }
    #viewTools .toggle a {
      display: block;
      color: #C4C6C7;
      text-decoration: none;
      margin: 0 0px 20px 30px; }
      @media (max-width: 1139.98px) {
        #viewTools .toggle a .label {
          display: none; } }
      #viewTools .toggle a svg {
        fill: #C4C6C7;
        stroke: #C4C6C7; }
      #viewTools .toggle a:hover {
        color: #414648; }
        #viewTools .toggle a:hover svg {
          fill: #414648;
          stroke: #414648; }
      #viewTools .toggle a.active {
        color: #414648; }
        #viewTools .toggle a.active svg {
          fill: #414648;
          stroke: #414648; }

#calendarFilter .dateFilter {
  margin-bottom: 10px;
  padding: 0 10px; }
  #calendarFilter .dateFilter h3 {
    display: none;
    font-family: 'Zilla Slab', serif;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 30px; }
    @media (max-width: 799.98px) {
      #calendarFilter .dateFilter h3 {
        display: block; } }
  #calendarFilter .dateFilter ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: flex-start; }
    @media (max-width: 799.98px) {
      #calendarFilter .dateFilter ul {
        flex-direction: column; } }
    #calendarFilter .dateFilter ul li {
      margin-right: 20px; }
      @media (max-width: 799.98px) {
        #calendarFilter .dateFilter ul li {
          margin-bottom: 20px;
          margin-right: 0; } }
      #calendarFilter .dateFilter ul li label {
        position: relative; }
        #calendarFilter .dateFilter ul li label#clear {
          display: none; }
          #calendarFilter .dateFilter ul li label#clear.show {
            display: block; }
        #calendarFilter .dateFilter ul li label .labelTitle {
          color: #535C3E;
          font-family: 'Montserrat', serif;
          font-size: 16px;
          margin-left: 32px;
          cursor: pointer; }
          #calendarFilter .dateFilter ul li label .labelTitle.delete {
            color: #DB2B27; }
        #calendarFilter .dateFilter ul li label input[type="radio"] {
          display: none; }
        #calendarFilter .dateFilter ul li label input[type="text"] {
          width: 0;
          opacity: 0;
          position: absolute; }
        #calendarFilter .dateFilter ul li label .checkboxBg {
          background: #E7E7E1;
          border: 1px solid #AEB1B2;
          border-radius: 50%;
          bottom: 7px;
          content: ' ';
          height: 20px;
          left: 0px;
          position: absolute;
          width: 20px;
          cursor: pointer; }
          #calendarFilter .dateFilter ul li label .checkboxBg svg {
            opacity: 0;
            left: 50%;
            max-height: 12px;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 12px;
            transition: all 200ms cubic-bezier(0.4, 0.02, 0.15, 1); }
        #calendarFilter .dateFilter ul li label input[type="radio"] {
          display: none; }
          #calendarFilter .dateFilter ul li label input[type="radio"]:checked ~ .checkboxBg svg {
            opacity: 1; }
          #calendarFilter .dateFilter ul li label input[type="radio"]:checked ~ .labelTitle {
            color: #DB2B27; }

body.eventOverviewPage section.highlights h1, body.locationOverviewPage section.highlights h1, body.routeOverviewPage section.highlights h1 {
  font-family: 'Zilla Slab', serif;
  font-size: 45px;
  line-height: 50px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em; }

body.eventPage section.pageContent, body.routePage section.pageContent, body.locationPage section.pageContent {
  padding-top: 75px; }
  body.eventPage section.pageContent p, body.routePage section.pageContent p, body.locationPage section.pageContent p {
    margin-bottom: 30px; }

body.searchResultsPage section.highlights {
  text-align: left; }
  body.searchResultsPage section.highlights div.row {
    padding-bottom: 40px; }
  body.searchResultsPage section.highlights h1 {
    font-family: 'Zilla Slab', serif;
    font-size: 45px;
    line-height: 50px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em; }
    @media (max-width: 799.98px) {
      body.searchResultsPage section.highlights h1 {
        font-size: 25px;
        line-height: 25px; } }

body section.searchResults {
  position: relative; }
  body section.searchResults div.search {
    display: none; }
  body section.searchResults span.resultText {
    font-family: 'Zilla Slab', serif;
    font-size: 24px;
    line-height: 30px;
    margin: 40px 0;
    display: inline-block; }
  body section.searchResults ul.results {
    list-style: none;
    margin: 0;
    padding: 0; }
    body section.searchResults ul.results li.result {
      margin: 30px 0; }
      body section.searchResults ul.results li.result div.information a h2 {
        font-family: 'Zilla Slab', serif;
        font-size: 30px;
        line-height: 30px;
        font-weight: 700;
        color: #7299A8; }
      body section.searchResults ul.results li.result div.information a span.intro {
        font-family: 'Montserrat', serif;
        font-size: 18px;
        line-height: 30px;
        color: #414648;
        display: block; }
      body section.searchResults ul.results li.result div.information a span.link {
        display: block;
        font-family: 'Zilla Slab', serif;
        font-size: 16px;
        line-height: 30px;
        color: #7299A8; }
        @media (max-width: 1139.98px) {
          body section.searchResults ul.results li.result div.information a span.link {
            word-break: break-word; } }
      body section.searchResults ul.results li.result div.information a:focus, body section.searchResults ul.results li.result div.information a:active, body section.searchResults ul.results li.result div.information a:hover {
        text-decoration: none; }
        body section.searchResults ul.results li.result div.information a:focus span.link, body section.searchResults ul.results li.result div.information a:active span.link, body section.searchResults ul.results li.result div.information a:hover span.link {
          text-decoration: underline; }
  @media (max-width: 799.98px) {
    body section.searchResults span.resultText {
      margin: 10px 0;
      font-size: 20px;
      line-height: 20px; }
    body section.searchResults div.search {
      display: block;
      margin: 10px 0; }
      body section.searchResults div.search input {
        font-size: 14px;
        line-height: 18px; }
    body section.searchResults ul.results li.result div.information a h2 {
      font-size: 20px;
      line-height: 20px; }
    body section.searchResults ul.results li.result div.information a span.intro {
      font-size: 14px;
      line-height: 20px; } }

body.storiesOverviewPage section.pageContent {
  padding-bottom: 0; }

body.storiesOverviewPage #calendarFilter .themeFilter {
  margin-bottom: 30px; }
  @media (max-width: 799.98px) {
    body.storiesOverviewPage #calendarFilter .themeFilter {
      margin-bottom: 50px; } }

body.timeLineOverviewPage section.pageContent {
  padding-bottom: 0; }

body section.experience p.leader {
  width: 70%;
  margin: 0 auto; }

body section.experience ul.listBlockView {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: flex-start;
  margin: 50px 0 40px;
  padding: 0 10px; }
  @media (max-width: 1439.98px) {
    body section.experience ul.listBlockView {
      justify-content: space-between; } }
  @media (max-width: 799.98px) {
    body section.experience ul.listBlockView {
      padding: 0; } }
  body section.experience ul.listBlockView li {
    background: #F7F8F9;
    width: 400px;
    /*margin-left: 5px;*/
    margin-bottom: 20px;
    margin-right: 20px;
    position: relative;
    text-align: left;
    transition: all 0.5s cubic-bezier(0.4, 0.02, 0.15, 1); }
    body section.experience ul.listBlockView li * {
      transition: all 0.5s cubic-bezier(0.4, 0.02, 0.15, 1); }
    body section.experience ul.listBlockView li:focus, body section.experience ul.listBlockView li:active, body section.experience ul.listBlockView li:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }
      body section.experience ul.listBlockView li:focus a .imgHolder img, body section.experience ul.listBlockView li:active a .imgHolder img, body section.experience ul.listBlockView li:hover a .imgHolder img {
        transform: scale(1.05); }
    body section.experience ul.listBlockView li:nth-of-type(3n) {
      margin-right: 0px; }
    @media (max-width: 1439.98px) {
      body section.experience ul.listBlockView li {
        width: 32%;
        margin-right: 0px; } }
    @media (max-width: 1139.98px) {
      body section.experience ul.listBlockView li {
        width: 49%;
        margin-right: 0px; } }
    @media (max-width: 799.98px) {
      body section.experience ul.listBlockView li {
        width: 100%;
        margin-right: 0px; } }
    body section.experience ul.listBlockView li.hide {
      display: none; }
    body section.experience ul.listBlockView li a {
      text-decoration: none; }
      body section.experience ul.listBlockView li a .imgHolder {
        overflow: hidden; }
      body section.experience ul.listBlockView li a .icons {
        position: relative;
        margin-top: -52px; }
      body section.experience ul.listBlockView li a .date {
        position: absolute;
        top: 20px;
        left: 30px;
        font-family: 'Zilla Slab', serif;
        font-size: 22px;
        color: #F7F8F9;
        line-height: 22px;
        /*text-transform: uppercase;*/
        font-weight: bold;
        text-shadow: 2px 2px 2px #000000;
        z-index: 3; }
      body section.experience ul.listBlockView li a .listItemContent {
        padding: 20px 30px; }
        body section.experience ul.listBlockView li a .listItemContent .title {
          font-family: 'Zilla Slab', serif;
          font-weight: normal;
          font-size: 30px;
          line-height: 30px;
          display: block;
          margin-top: 20px;
          margin-bottom: 5px; }
        body section.experience ul.listBlockView li a .listItemContent p {
          font-family: 'Montserrat', serif;
          font-size: 16px;
          line-height: 25px;
          color: #535C3E; }

body.homepage section.highlights {
  top: -14px; }

body {
  font-family: 'Montserrat', serif;
  font-size: 18px;
  color: #414648;
  line-height: 30px; }

h1, h2, h3, h4,
.h1, .h2, .h3, .h4,
.title {
  font-family: 'Zilla Slab', serif;
  font-size: 25px;
  color: #F7F8F9;
  line-height: 40px;
  /*text-transform: uppercase;*/ }

h1, .h1 {
  font-size: 60px;
  line-height: 50px;
  font-weight: 700; }
  @media (max-width: 799.98px) {
    h1, .h1 {
      font-size: 25px;
      line-height: 25px;
      letter-spacing: 0.2em; } }

h2, .h2 {
  font-size: 45px;
  line-height: 40px;
  font-weight: 700; }

h3, .h3 {
  color: #414648;
  font-size: 35px;
  line-height: 30px;
  font-weight: 700;
  text-transform: unset; }

h4, .h4, .title {
  color: #414648;
  font-size: 30px;
  line-height: 25px;
  font-weight: 700; }

a {
  color: #7299A8; }
  .pageContent a {
    font-weight: bold;
    text-decoration: underline; }
    .pageContent a:hover {
      text-decoration: none; }
  a:hover {
    color: #7299A8; }

body.timeLinePage span.chapeau, body.storyPage span.chapeau {
  font-size: 18px; }

.languagepicker {
  /*background-color: #FFF;*/
  display: inline-block;
  padding: 0;
  height: 40px;
  overflow: hidden;
  transition: all .3s ease;
  margin: 0 50px 10px 0;
  vertical-align: top;
  float: left; }
  .languagepicker a {
    color: #000;
    text-decoration: none; }
    .languagepicker a li {
      display: block;
      padding: 0px 20px;
      line-height: 40px; }
      .languagepicker a li:hover {
        /*background-color: #EEE;*/ }
      .languagepicker a li span.short {
        display: inline-block; }
      .languagepicker a li span.full {
        display: none; }
    .languagepicker a:first-child li {
      border: none;
      /*background: #FFF !important;*/ }
  .languagepicker:hover {
    height: 81px; }
    .languagepicker:hover a li span.short {
      display: none; }
    .languagepicker:hover a li span.full {
      display: inline-block; }

span.chapeau {
  font-family: 'Montserrat', serif;
  font-size: 20px;
  color: #414648;
  line-height: 40px;
  font-weight: 700;
  text-transform: uppercase;
  color: #F7F8F9;
  margin-bottom: 15px;
  display: block; }
  @media (max-width: 799.98px) {
    span.chapeau {
      font-size: 14px;
      line-height: 18px; } }

a.back {
  font-family: 'Montserrat', serif;
  font-size: 18px;
  color: #414648;
  line-height: 22px;
  color: #F7F8F9; }
  a.back svg {
    position: relative;
    transition: all 400ms cubic-bezier(0.4, 0.02, 0.15, 1); }
  a.back:hover {
    text-decoration: none; }
    a.back:hover svg {
      transform: translateX(-5px); }

p.intro {
  font-family: 'Zilla Slab', serif;
  font-size: 25px;
  color: #F7F8F9;
  line-height: 40px;
  /*text-transform: uppercase;*/
  color: #414648;
  text-transform: unset;
  margin: 50px 0; }
  @media (max-width: 1139.98px) {
    p.intro {
      font-size: 20px;
      line-height: 30px; } }

div.imageHolderUrh {
  width: 100%;
  height: 100%; }

div.centered {
  display: flex;
  justify-content: center; }

.takeTimelineBackgroundColor {
  background-color: #414648; }

.takeTimelineColor {
  color: #414648; }

svg.takeTimelineFill use {
  fill: #414648; }

.geel .takeTimelineBackgroundColor {
  background-color: #CDA33B; }

.geel .takeTimelineColor {
  color: #CDA33B; }

.geel .takeTimelineFill use {
  fill: #CDA33B; }

.geel > .takeTimelineFillFromPrevious use {
  fill: #CDA33B; }

.blauw .takeTimelineBackgroundColor {
  background-color: #5C8C99; }

.blauw .takeTimelineColor {
  color: #5C8C99; }

.blauw .takeTimelineFill use {
  fill: #5C8C99; }

.blauw > .takeTimelineFillFromPrevious use {
  fill: #5C8C99; }

.groen .takeTimelineBackgroundColor {
  background-color: #437343; }

.groen .takeTimelineColor {
  color: #437343; }

.groen .takeTimelineFill use {
  fill: #437343; }

.groen > .takeTimelineFillFromPrevious use {
  fill: #437343; }

.paars .takeTimelineBackgroundColor {
  background-color: #995C7C; }

.paars .takeTimelineColor {
  color: #995C7C; }

.paars .takeTimelineFill use {
  fill: #995C7C; }

.paars > .takeTimelineFillFromPrevious use {
  fill: #995C7C; }

.roze .takeTimelineBackgroundColor {
  background-color: #c2185E; }

.roze .takeTimelineColor {
  color: #c2185E; }

.roze .takeTimelineFill use {
  fill: #c2185E; }

.roze > .takeTimelineFillFromPrevious use {
  fill: #c2185E; }

.bruin .takeTimelineBackgroundColor {
  background-color: #7A4B2C; }

.bruin .takeTimelineColor {
  color: #7A4B2C; }

.bruin .takeTimelineFill use {
  fill: #7A4B2C; }

.bruin > .takeTimelineFillFromPrevious use {
  fill: #7A4B2C; }

body div.svgHolder {
  position: absolute;
  width: 100%;
  overflow-x: hidden; }
  body div.svgHolder.edge-header-bottom, body div.svgHolder.edge-subheader-bottom {
    top: -35px; }
  body div.svgHolder.edge-section-top {
    top: -35px; }
  body div.svgHolder.edge-section-bottom {
    bottom: 0; }

o ol {
  counter-reset: item; }
  o ol > li:before {
    content: counter(item) ".";
    counter-increment: item; }
  o ol > li > ol {
    counter-reset: subitem; }
    o ol > li > ol > li:before {
      content: counter(subitem) ".";
      counter-increment: subitem; }
    o ol > li > ol > li > ol {
      counter-reset: subsubitem; }
      o ol > li > ol > li > ol > li:before {
        content: counter(subsubitem) ".";
        counter-increment: subsubitem; }

img {
  max-width: 100%; }

.row > .col.flex,
.row > [class*="col-"].flex {
  display: flex; }

@media print {
  footer {
    display: none; }
  header {
    background: transparent; }
    header .logo {
      display: block; }
    header nav {
      display: none; } }
