﻿@media print {
	footer {
		display: none;
	}

	header {
		background: transparent;

		.logo {
			display: block;
		}

		nav {
			display: none;
		}
	}
}
