﻿/// <reference path="../../settings/variables" />
/// <reference path="../../settings/mixins" />

body {
	section.searchResults {
		position: relative;

		div.search {
			display: none;
		}

		span.resultText {
			@include fontZilla();
			@include fontSize(24px, 30px);
			margin: 40px 0;
			display: inline-block;
		}

		ul.results {
			list-style: none;
			margin: 0;
			padding: 0;

			li.result {
				margin: 30px 0;

				div.information {
					a {
						h2 {
							@include fontZilla();
							@include fontSize(30px, 30px, 700);
							color: $colorPrimaryBlue;
						}

						span.intro {
							@include fontMontserrat();
							@include fontSize(18px, 30px);
							color: $colorText;
							display: block;
						}

						span.link {
							display: block;
							@include fontZilla();
							@include fontSize(16px, 30px);
							color: $colorPrimaryBlue;

							@include media-breakpoint-down(md) {
								word-break: break-word;
							}
						}

						@include active() {
							text-decoration: none;

							span.link {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-down(sm) {

			span.resultText {
				margin: 10px 0;
				@include fontSize(20px, 20px);
			}

			div.search {
				display: block;
				margin: 10px 0;

				input {
					@include fontSize(14px, 18px);
				}
			}

			ul.results{
				li.result{
					div.information{
						a{
							h2{
								@include fontSize(20px, 20px);
							}
							
							span.intro{
								@include fontSize(14px, 20px);
							}
						}
					}
				}
			}
		}
	}
}
