@keyframes swatchTileAppear {
	from {
		opacity: 0;
		transform: translateY(-10px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes rotateQuarter {
	from {
		transform: rotate(0);
	}

	to {
		transform: rotate(90deg);
	}
}

@keyframes rotateHalf {
	from {
		transform: rotate(0);
	}

	to {
		transform: rotate(180deg);
	}
}

@keyframes rotate {
	from {
		transform: rotate(0);
	}

	to {
		transform: rotate(360deg);
	}
}

@keyframes cartAnimate {
	0% {
		transform: rotate(0);
	}

	25% {
		transform: rotate(10deg);
	}

	50% {
		transform: rotate(-10deg);
	}

	75% {
		transform: rotate(10deg);
	}

	100% {
		transform: rotate(0);
	}
}