﻿/// <reference path="../../settings/variables" />
/// <reference path="../../settings/mixins" />

#listview {
	@include transition(all,300ms);
	opacity: 0;
	height: 0;
	pointer-events: none;
	overflow:hidden;

	body.storiesOverviewPage & {
		opacity: 1;
		height: auto;
		pointer-events: all;
	}

	&.show {
		opacity: 1;
		height: auto;
		pointer-events: all;
	}

	&.fade{
		opacity:0;
	}

	ul.listBlockView {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		justify-content: flex-start;
		margin: -10px;
		padding: 0 10px;

		@include media-breakpoint-down(lg) {
			justify-content: space-between;
		}

		@include media-breakpoint-down(sm) {
			padding: 0;
		}

		li {
			background: $colorPrimaryGreyTint1;
			width: calc(33% - 20px);
			margin: 10px;
			position: relative;

			@include transition(all, 0.5s);

			& * {
				@include transition(all, 0.5s);
			}

			@include active() {
				box-shadow: 0 0 10px rgba(0,0,0,0.3);

				a {
					.imgHolder {
						img {
							transform: scale(1.05);
						}
					}
				}
			}

			@include media-breakpoint-down(lg) {
				width: calc(33% - 20px);
			}

			@include media-breakpoint-down(md) {
				width: calc(50% - 20px);
			}

			@include media-breakpoint-down(sm) {
				width: calc(100% - 20px);
			}

			&.hide {
				display: none;
			}

			a {
				text-decoration: none;

				.imgHolder {
					overflow: hidden;
				}

				.icons {
					position: relative;
					margin-top: -52px;
				}

				.date {
					position: absolute;
					top: 20px;
					left: 30px;
					@include fontSecondary(22px,22px);
					font-weight: bold;
					text-shadow: 2px 2px 2px $colorPrimaryGreyTextShadow;
					z-index: 2;
				}

				.listItemContent {
					padding: 20px 30px;

					.title {
						@include fontZilla();
						font-weight: normal;
						font-size: 30px;
						line-height: 30px;
						display: block;
						margin-top: 20px;
						margin-bottom: 5px;
					}

					p {
						@include fontMontserrat();
						font-size: 16px;
						line-height: 25px;
						color: $colorPrimaryGreyShade1;
					}
				}
			}
		}
	}
}
