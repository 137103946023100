﻿/// <reference path="../settings/variables" />
/// <reference path="../settings/mixins" />

body {
	section.contactBlock {
		display: flex;
		background-color: $colorPrimaryGreyTint1;

		div.contactInfo {
			padding: 30px;
			flex-basis: 50%;

			h2 {
				@include fontSize(25px, 40px);
				color: $colorText;
			}

			span {
				display: block;
				margin-bottom: 15px;
			}

			ul.contactButtons {
				list-style: none;
				display: flex;
				padding: 0;
				margin:0;

				li {
					text-align: center;

					a {
						text-decoration: none;
						color: $colorText;
						position: relative;
						min-width: 60px;
						padding-top: 60px;
						margin-right: 10px;
						display: block;
						@include fontSize(14px, 20px);

						svg {
							position: absolute;
							top: 0;
							background-color: $colorPrimaryBlue;
							width: 60px;
							max-height: 60px;
							padding: 15px;
							left: 0;
							right: 0;
							margin: auto;
							@include transition(opacity);

						}

						@include active()
						{
							svg{
								opacity:0.8;
							}
						}
					}
				}
			}
		}

		div.mapsImage {
			position:relative;
			flex-basis:50%;
			background-position:center;
			svg{
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:48px;
				margin:auto;
			}
		}
	}
}
