﻿/// <reference path="../settings/variables" />
/// <reference path="../settings/mixins" />

body {

	&.eventPage, &.routePage, &.locationPage {

		section.pageContent {
			padding-top:75px;
			p{
				margin-bottom:30px;
			}
		}
	}
}
