﻿/// <reference path="../settings/variables" />
/// <reference path="../settings/mixins" />

body {
	section.experience {

		p.leader {
			width: 70%;
			margin: 0 auto;
		}

		ul.listBlockView {
			display: flex;
			flex-wrap: wrap;
			list-style: none;
			justify-content: flex-start;
			margin: 50px 0 40px;
			padding: 0 10px;

			@include media-breakpoint-down(lg) {
				justify-content: space-between;
			}

			@include media-breakpoint-down(sm) {
				padding: 0;
			}

			li {
				background: $colorPrimaryGreyTint1;
				width: 400px;
				/*margin-left: 5px;*/
				margin-bottom: 20px;
				margin-right: 20px;
				position: relative;
				text-align: left;

				@include transition(all, 0.5s);

				& * {
					@include transition(all, 0.5s);
				}

				@include active() {
					box-shadow: 0 0 10px rgba(0,0,0,0.3);

					a {
						.imgHolder {
							img {
								transform: scale(1.05);
							}
						}
					}
				}

				&:nth-of-type(3n) {
					margin-right: 0px;
				}

				@include media-breakpoint-down(lg) {
					width: 32%;
					margin-right: 0px;
				}

				@include media-breakpoint-down(md) {
					width: 49%;
					margin-right: 0px;
				}

				@include media-breakpoint-down(sm) {
					width: 100%;
					margin-right: 0px;
				}

				&.hide {
					display: none;
				}

				a {
					text-decoration: none;

					.imgHolder {
						overflow: hidden;
					}

					.icons {
						position: relative;
						margin-top: -52px;
					}

					.date {
						position: absolute;
						top: 20px;
						left: 30px;
						@include fontSecondary(22px,22px);
						font-weight: bold;
						text-shadow: 2px 2px 2px $colorPrimaryGreyTextShadow;
						z-index:3;
					}

					.listItemContent {
						padding: 20px 30px;

						.title {
							@include fontZilla();
							font-weight: normal;
							font-size: 30px;
							line-height: 30px;
							display: block;
							margin-top: 20px;
							margin-bottom: 5px;
						}

						p {
							@include fontMontserrat();
							font-size: 16px;
							line-height: 25px;
							color: $colorPrimaryGreyShade1;
						}
					}
				}
			}
		}
	}
}
