﻿/// <reference path="../settings/variables" />
/// <reference path="../settings/mixins" />

body {

	button, .button {
		@include fontSecondary(20px,25px);
		text-transform: unset;
		display: inline-block;
		padding: 17px 70px;



		&.primary, &.secondary {
			color: $colorText;
			border-radius: 3px;
		}

		&.primary {
			background-color: $colorButtonPrimary;
			@include transition();

			&:hover {
				color: #fff;
				text-decoration: underline;
			}
		}

		&.secondary {
			background-color: $colorButtonSecondary;
			border: 1px solid $colorPrimaryGrey;
			font-weight: normal;
			text-decoration: none;
			@include transition(all,0.3s);

			&:hover {
				text-decoration: underline;
				border-color: $colorText;
			}
		}

		&.visual {
			position: relative;
			padding: 17px 60px;
			color: $colorTitle;
			font-weight: 700;

			&.red {
				svg {
					use {
						fill: $colorPrimaryRed;
					}
				}
			}

			&.blue {
				svg {
					use {
						fill: $colorPrimaryBlue;
					}
				}
			}
		}
	}
}
