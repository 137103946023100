﻿/// <reference path="../settings/variables" />
/// <reference path="../settings/mixins" />

body {
	footer {
		margin-top: 30px;

		section.footerImages {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.swipe {

				@include media-breakpoint-down(sm) {
					width: 100%;
				}

				img {
					margin-right: -20%;
					max-height: 120px;

					@include media-breakpoint-down(sm) {
						max-height: none;
					}
				}
			}

			.logo {
				padding: 50px 80px;

				img {
					max-height: 35px;
				}
			}

			@include media-breakpoint-down(sm) {
				flex-direction: column-reverse;
			}
		}

		section.footerLinks {
			background-color: $colorPrimaryGreyTint1;
			padding: 15px 0;
			text-align: center;

			ul {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				width: 100%;
				list-style: none;
				margin: 0;
				padding: 0;

				@include media-breakpoint-down(md) {
					flex-direction: column;
				}

				li {
					a {
						color: $colorPrimaryBlue;
						@include fontSize(16px, 19px);
					}

					@include media-breakpoint-up(lg) {
						&:not(:last-child) {
							&:after {
								content: '—';
								margin: 0 7px;
								color: $colorPrimaryBlue;
							}
						}
					}
				}

				&.languages {

					@include media-breakpoint-down(md) {
						margin-top: 40px;
						margin-bottom: 20px;

						display: flex;
						flex-direction: row;
						flex-flow: wrap;

						li {
							padding: 0 5px;

							a {
								color: $colorPrimaryGreen;
							}

							&.selected {
								a {
									text-decoration: underline;
								}
							}
						}
					}
				}
			}
		}
	}
}
