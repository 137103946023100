﻿/// <reference path="../../settings/variables" />
/// <reference path="../../settings/mixins" />

#viewTools {
	position: relative;

	@include media-breakpoint-down(md) {
		padding: 55px 0 0 0;
	}

	.mobileFilterToggle {
		display: none;

		@include media-breakpoint-down(md) {
			display: block;
			position: absolute;
			left: 15%;
			bottom: 17px;
			z-index: 2;
		}

		a {
			@include fontMontserrat();
			font-size: 16px;
			color: $colorPrimaryGreyShade1;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.toggle {
		position: absolute;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: flex-end;
		padding-right: 10px;
		z-index: 2;

		@include media-breakpoint-down(md) {
			padding-right: 0;
			right: 15%;
		}

		a {
			display: block;
			color: $colorPrimaryGreyInactiveThirty;
			text-decoration: none;
			margin: 0 0px 20px 30px;

			.label {
				@include media-breakpoint-down(md) {
					display: none;
				}
			}

			svg {
				fill: $colorPrimaryGreyInactiveThirty;
				stroke: $colorPrimaryGreyInactiveThirty;
			}

			&:hover {
				color: $colorPrimaryGreyShade2;

				svg {
					fill: $colorPrimaryGreyShade2;
					stroke: $colorPrimaryGreyShade2;
				}
			}

			&.active {
				color: $colorPrimaryGreyShade2;

				svg {
					fill: $colorPrimaryGreyShade2;
					stroke: $colorPrimaryGreyShade2;
				}
			}
		}
	}
}
