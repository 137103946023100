﻿/// <reference path="../settings/variables" />
/// <reference path="../settings/mixins" />

body {

	section.timelineOverview {
		div.timeline {
			position: relative;
			padding-bottom: 35px;

			div.inner {
				display: flex;
				justify-content: space-between;
				align-items: center;

				div.imageHolder {
					max-width: 360px;
					width: 100%;
					height: 100%;
					position: relative;
					margin: 30px 30px;

					@include media-breakpoint-down(lg) {
						max-width:300px;
					}
				}

				div.textHolder {
					max-width: 630px;
					margin: 30px 0;

					p {
						margin: 25px 0;

						@include media-breakpoint-down(sm) {
							font-size: 16px;
						}
					}

					a {
						@include fontZilla();
						font-size: 22px;
						font-weight: bold;
						line-height: 27px;
						padding-right: 20px;
						position: relative;


						svg {
							right: 0;
							margin-left: 0px;
							@include transition();
						}

						&:hover {
							text-decoration: none;

							svg {
								margin-left: 5px;
							}
						}
					}
				}
			}



			&:nth-child(odd) {
				background-color: $colorPrimaryGreyTint1;

				div.svgHolder, div.imageHolder {
					svg {
						use {
							fill: $colorPrimaryGreyTint1;
						}
					}
				}
			}

			&:nth-child(even) {
				div.svgHolder {
					transform: scaleX(-1);
				}

				div.inner {
					flex-direction: row-reverse;
				}

				background-color: white;

				div.svgHolder, div.imageHolder {
					svg {
						use {
							fill: white;
						}
					}
				}
			}

			@include media-breakpoint-down(sm) {
				div.inner {
					flex-direction: column;

					div.imageHolder {
						max-width: calc(100% - 80px);
					}

					div.textHolder {
						h2 {
							@include fontSize(35px, 40px);
						}
					}
				}

				&:nth-child(even) {
					div.inner {
						flex-direction: column;
					}
				}
			}
		}
	}
}
