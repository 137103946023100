﻿/// <reference path="../settings/variables" />
/// <reference path="../settings/mixins" />

body {

	section.discover {
		text-align: center;
		position: relative;

		h2 {
			@include fontVisual();
			color: $colorText;
			margin-top: 10px;
			margin-bottom: 15px;
			text-transform: unset;

			@include media-breakpoint-down(sm) {
				font-size: 30px;
			}
		}

		div.svgHolder.grey {
			svg {
				use {
					fill: $colorPrimaryGrey;
				}
			}
		}

		section.discoverIcons {
			position: relative;
			background-color: $colorPrimaryGrey;
			margin-top: 100px;
			padding-top: 30px;

			div.timelines {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				margin-bottom: -40px;

				@include media-breakpoint-down(sm) {
					justify-content: space-evenly;
					padding: 0 20px;
				}


				svg {
					position: absolute;
					left: 0;
					right: 0;
					margin: 0 auto;
					top: -100px;
					@include transition(top);

					image {
						&.image_sm {
							display: none;
						}
					}
				}

				a.timeline {
					width: 145px;
					margin-bottom: 120px;
					position: relative;
					color: $colorText;
					@include fontZilla();
					@include fontSize(20px, 20px);
					font-weight: bold;
					padding: 20px 0;

					@include active() {
						text-decoration: none;

						svg {
							top: -115px;
						}
					}

					@include media-breakpoint-down(sm) {
						font-size: 16px;
						width: 100px;
						padding: 0;

						svg.icon {
							width: 75px;

							image {
								display: none;

								&.image_sm {
									display: block;
								}
							}
						}
					}

					@include media-breakpoint-down(md) {
						flex-basis: 33%;
					}

					@include media-breakpoint-down(xs) {
						flex-basis: 40%;
					}
				}
			}
		}
	}
}
