﻿/*@import './components/file';*/
@import './components/button';
@import './components/experience';
@import './components/discover';


@import './components/header';

@import './components/pageHeader';
@import './components/highlight';
@import './components/carousel';
@import './components/timelineOverview';
@import './components/pageContent';
@import './components/additionalInformation';
@import './components/contactBlock';
@import './components/pager';
@import './components/footer';


@import './components/cookiebar';
