﻿/// <reference path="../settings/variables" />
/// <reference path="../settings/mixins" />

body {

	section.highlights {
		text-align: center;
		position: relative;
		padding-top: 40px;

		&.primaryBlue {
			background: $colorPrimaryBlue;

			.takeTimelineFill use {
				fill: $colorPrimaryBlue;
			}
		}

		h2 {
			@include fontVisual();
			margin-bottom: 15px;
		}


		.row {
			padding-bottom: 70px;
		}

		a.showAllLink {
			color: $colorPrimaryGreyTint1;
		}

		@include media-breakpoint-down(sm) {
			h2 {
				@include fontSize(30px, 30px);
			}

			a.showAllLink {
				@include fontSize(14px, 18px);

				svg {
					vertical-align: middle;
				}
			}
		}
	}
}
