﻿/// <reference path="../settings/variables" />
/// <reference path="../settings/mixins" />

body {

	section.additionalInformation {
		h2 {
			color: $colorText;
			@include fontSize(25px, 40px);
		}

		div.infoHolder {
			padding-right:60px;
			div.occurrenceRule, div.priceRule {
				padding:10px 0;
				display: flex;
				border-top: 1px solid $colorPrimaryGreyTint2;

				span{
					@include fontSize(16px, 20px);
				}

				span.occurrence, span.name{
					flex:0 1 250px;
				}

				span.time, span.value {
					white-space: nowrap;
					font-style:italic;
				}

				&:last-of-type {
					border-bottom: 1px solid $colorPrimaryGreyTint2;
				}
			}

			p.description {
				@include fontSize(16px, 30px);
				font-style:italic;
				border-top: 1px solid $colorPrimaryGreyTint2;
				flex-basis: 100%;
			}
		}
	}
}
